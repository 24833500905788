import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { initializeFirebase } from './lib/firebase';
import { AppProvider } from '@/providers/app';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '@/features/misc/error-page';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { Suspense } from 'react';
import { Spinner } from '@/components/icons';
import { Toaster } from '@/providers/toaster';

initializeFirebase();

const fallback = (
  <div className='h-screen grid place-items-center'>
    <Spinner size={28} />
  </div>
);

function App() {
  return (
    <AppProvider>
      <ErrorBoundary fallback={<ErrorPage />}>
        <Suspense fallback={fallback}>
          <RouterProvider router={router} />
        </Suspense>
        <Toaster />
        <ToastContainer />
      </ErrorBoundary>
    </AppProvider>
  );
}

export default App;
