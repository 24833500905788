import { Button } from '../../../../components/ui';
import {
  JsonPropertyTypeString,
  PropertyProps,
  ArrayPropertyProps,
  ObjectPropertyProps,
  StringArrayPropertyProps,
  MapPropertyProps,
} from './types';
import {
  addArraySignature,
  composeNewArrayValue,
  getCardinal,
  isDependenciesSatisfied,
  pushArray,
  expandExpression,
  composeNewMapValue,
  // persistFromProperties,
} from './utils';
import {
  BooleanProperty,
  ComputedProperty,
  DateProperty,
  EnumProperty,
  NumberProperty,
  StringProperty,
  TagInputProperty,
} from './primitives';
import { useContext, useMemo } from 'react';
import { FormBuilderContext } from './context';
import { PropertyTitle } from './component';
import { usePropertyValue } from './hooks';
import { useEffect } from 'react';


const PropertyItems = {
  string: StringProperty,
  number: NumberProperty,
  boolean: BooleanProperty,
  array: ArrayProperty,
  object: ObjectProperty,
  enum: EnumProperty,
  map: MapProperty,
  date: DateProperty,
  computed: ComputedProperty,
} as Record<JsonPropertyTypeString, (props: PropertyProps) => JSX.Element>;

export function Property(props: PropertyProps) {
  const { property } = props;

  const hasDependenciesBeenSatisfied = useMemo(() => {
    return isDependenciesSatisfied(property.dependencies, props.state);
  }, [property.dependencies, props.state]);

  // useEffect(() => {
  //   // If the dependencies are not satisfied, we set the value and error to undefined
  //   if (!hasDependenciesBeenSatisfied) {
  //     props.onChange(undefined);
  //     props.setError(undefined);
  //   }
  // }, [hasDependenciesBeenSatisfied])

  if (!hasDependenciesBeenSatisfied) {
    return null;
  }

  const PropertyComponent = PropertyItems[property.type] ?? PropertyItems['string'];
  return <PropertyComponent {...props} />;
}

function ArrayProperty(props: ArrayPropertyProps) {
  const context = useContext(FormBuilderContext);

  const {
    property,
    name,
    error = [],
    setError,
    value: _value,
    onChange,
  } = props;

  const offset = useMemo(() => {
    return property.offset ?? 0;
  }, [property.offset]);

  const [value, setValue] = usePropertyValue(
    Array.isArray(_value) ? _value : undefined, // make sure the value is an array
    onChange,
    () => composeNewArrayValue(props.property, context?.extra),
    (value) => addArraySignature(props.property, value)
  );

  if (
    property.items.type === "string" &&
    property.items.widget !== "textarea"
  ) {
    return (
      <TagInputProperty
        {...({
          ...props,
          property: {
            ...property.items,
            title: property.items.title ?? property.title,
          },
        } as StringArrayPropertyProps)}
      />
    );
  }

  return (
  
    <div className="space-y-4">
      <PropertyTitle property={property} context={props.context} />
      {typeof error === "string" && <div>{error}</div>}
      <div className="space-y-6">
        {value.length === 0 && (
          <div className="text-muted-foreground">
            No items. Click on the add button to add some
          </div>
        )}

        {value.map((item, index) => (
          <div className="flex gap-2" key={index}>
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-8 h-8 bg-primary/10 rounded-md">
                {offset + index + 1}
              </div>
            </div>
            <div className="grow border-l-2 border-primary pl-4 space-y-4">
              <Property
                key={`${name}.${index}`}
                name={`${name}.${index}`}
                property={property.items}
                value={item}
                state={props.state}
                context={props.context}
                error={error?.[index]}
                setError={(err) => {
                  const nextError = { ...error };
                  nextError[index] = err;
                  setError(nextError);
                }}
                onChange={(setter) => {
                  setValue((prevValue) => {
                    const nextValue = [...prevValue];
                    nextValue[index] = setter;
                    return nextValue;
                  });
                }}
              />
              <div>
                {property.removeLabel !== false ||
                (property.length && value.length < property.length) ||
                (property.max && value.length <= property.max) ? (
                  <Button
                    variant="outline"
                    size="sm"
                    className="hover:bg-danger/10"
                    onClick={() =>
                      setValue((prev) => {
                        const next = prev.filter((_, i) => i !== index);
                        if (property.items.type !== "object") {
                          return next;
                        }
                        // update the cardinal and index of the next items
                        for (let i = index; i < next.length; i++) {
                          next[i] = {
                            ...next[i],
                            __cardinal: getCardinal(offset + i + 1), // update the cardinal!
                            __index: i, // update the index!
                          };
                        }
                        return next;
                      })
                    }
                  >
                    Remove
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        {property.addLabel !== false ||
        (property.length && value.length < property.length) ||
        (property.max && value.length < property.max) ||
        (property.min && value.length > property.min) ? (
          <Button
            variant="outline"
            onClick={() => {
              setValue((prevValue) => {
                const nextValue = [...prevValue];
                nextValue.push(pushArray(property, nextValue.length));

                return nextValue;
              });
            }}
          >
            {property.addLabel ?? "Add"}
          </Button>
        ) : null}
      </div>
    </div>
  );
}

function ObjectProperty(props: ObjectPropertyProps) {
  const {
    property,
    name,
    value: _value,
    error = {},
    setError,
    onChange,
  } = props;
  const [value, setValue] = usePropertyValue(_value, onChange, {});

  return (
    <div>
      <PropertyTitle property={property} context={props.context} />
      <div className="space-y-6">
        {Object.entries(property.properties).map(([key, prop]) => (
          <Property
            key={key}
            name={`${name}.${key}`}
            property={prop}
            value={value[key]}
            state={value}
            context={props.context}
            error={error?.[key]}
            setError={(err) => {
              const nextError = { ...error };
              nextError[key] = err;
              setError(nextError);
            }}
            onChange={(newValue) => {
              setValue((prevValue) => {
                const nextValue = { ...prevValue };
                if (newValue === undefined) {
                  delete nextValue[key];
                } else {
                  nextValue[key] = newValue;
                }
                return nextValue;
              });
            }}
          />
        ))}
      </div>
    </div>
  );
}

function MapProperty(props: MapPropertyProps) {
  const context = useContext(FormBuilderContext);
  const { property, name, value: _value, error = [], setError, onChange } = props;

  const from = useMemo(() => {
    const result = expandExpression(property.from, context?.state);
    return Array.isArray(result) ? result : [];
  }, [property.from, context?.state]);

  const [value, setValue] = usePropertyValue(
    _value,
    onChange,
    () => {
      const newValue = composeNewMapValue(props.property, from.length, context?.extra);
      return newValue;
    },
    (value) => {
      if (property.items.type !== "object") return value;
      const fromProperties = Object.entries(property.fromProperties ?? {});
      for (let i = 0; i < from.length; i++) {
        value[i] = value[i] || {};
        for (const [key, fromKey] of fromProperties) {
          value[i][fromKey] = from[i][key];
        }
      }

      return value;
    }
  );

  useEffect(() => {
    if (from.length !== value.length) {
      setValue((prevValue) => {
        let nextValue = [...prevValue];

        if (from.length > value.length) {
          const extra = composeNewMapValue(props.property, from.length - value.length, context?.extra);
          console.log("Adding extra items to value:", extra);
          nextValue = [...nextValue, ...extra];
        } else {
          console.log("Trimming value to match 'from' length");
          nextValue = nextValue.slice(0, from.length);
        }

        // Reapply the `fromProperties` to ensure fields like `definition` are not lost
        const fromProperties = Object.entries(property.fromProperties ?? {});
        for (let i = 0; i < from.length; i++) {
          nextValue[i] = nextValue[i] || {};
          for (const [key, fromKey] of fromProperties) {
            console.log(`Ensuring mapping from '${key}' to '${fromKey}' for index ${i}`);
            nextValue[i][fromKey] = from[i][key];
          }
        }
        return nextValue;
      });
    }
  }, [from, value.length]); // Adding `value.length` as a dependency
  props.state[name] = value;

  return (
    <div>
      <PropertyTitle property={property} context={props.context} />
      <div className="space-y-6">
        {!value.length && (
          <div className="text-muted-foreground">
            No items. Click on the add button to add some
          </div>
        )}
        {from.map((ob, index) => {
          return (
            <Property
              key={`${name}.${index}`}
              name={`${name}.${index}`}
              property={property.items}
              value={value[index]}
              state={value}
              context={ob}
              error={error[index]}
              setError={(err) => {
                const nextError = { ...error };
                nextError[index] = err;
                setError(nextError);
              }}
              onChange={(newValue) => {
                setValue((prevValue) => {
                  const nextValue = [...prevValue];
                  const fromProperties = Object.entries(property.fromProperties ?? {});
                  if (newValue === undefined) {
                    delete nextValue[index];
                  } else {
                    nextValue[index] = newValue;
                    for (const [key, fromKey] of fromProperties) {
                      nextValue[index][fromKey] = from[index]?.[key];
                    }
                  }
                  return nextValue;
                });
              }}
            />
          );
        })}
      </div>
    </div>
  );
}