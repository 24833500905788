import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues
} from 'react-hook-form';
import { Checkbox } from '../../../components/ui/checkbox';
import { Label } from '../../../components/ui/label';
import { Avatar, AvatarFallback, AvatarImage } from '../../../components/ui/avatar';
import { useMemo, useState } from 'react';
import { useUser } from '@/providers/auth';
import { Button, Input } from '@/components/ui'; // Added Input component for the search bar
import { Check } from 'lucide-react';
import { useRoles } from '../hooks/use-roles';

interface MembersFormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
  isDisabled?: (id: string) => boolean;
}

const MembersFormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: MembersFormFieldProps<TFieldValues, TName>) => {
  const { company } = useUser('MembersFormField');
  const roles = useRoles('MembersFormField');
  
  const [selectedRoles, setSelectedRoles] = useState<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = useState<string>(''); // Added state for search term

  const members = useMemo(() => {
    if (!company?.members) return [];
    return Object.values(company.members)
      .filter((member) => 
        member.name.toLowerCase().includes(searchTerm.toLowerCase()) // Filter members based on search term
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [company.members, searchTerm]); // Added searchTerm as a dependency

  const roleMap = useMemo(() => {
    const map = {} as Record<string, string[]>;

    members.forEach((member) => {
      const roleId = roles.normalizeRoleId(member.role);
      if (!map[roleId]) {
        map[roleId] = [];
      }
      map[roleId].push(member.id);
    });

    return map;
  }, [members]);

  const toggleRole = (role: string) => {
    const newSelectedRoles = new Set(selectedRoles);
    if (selectedRoles.has(role)) {
      newSelectedRoles.delete(role);
    } else {
      newSelectedRoles.add(role);
    }
    setSelectedRoles(newSelectedRoles);
  };

  const isAdmin = (id: string) => company?.members[id]?.role === 'admin';

  return (
    <div className='space-y-4'>
      {/* Search bar */}
      <Input
        placeholder='Search members...'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)} // Update search term when user types
      />

      {/* Roles selection */}
      <div className='flex flex-wrap gap-2'>
        {roles.data.map((role) => (
          <Controller
            key={`role-${role.id}`}
            {...props}
            render={({ field }) => (
              <Button
                variant={selectedRoles.has(role.id) ? 'default' : 'outline'}
                onClick={() => {
                  if (role.id in roleMap) {
                    if (selectedRoles.has(role.id)) {
                      // Remove all members with this role
                      field.onChange(
                        field.value.filter((id: string) => props.isDisabled?.(id) || !roleMap[role.id].includes(id))
                      );
                    } else {
                      // Add all members with this role
                      const visited = new Set<string>(field.value);
                      field.onChange([
                        ...field.value,
                        ...roleMap[role.id].filter((id) => !props.isDisabled?.(id) && !visited.has(id))
                      ]);
                    }
                  }
                  toggleRole(role.id);
                }}
                className='gap-2 h-8 capitalize transition-all'
              >
                {role.name} {selectedRoles.has(role.id) && <Check size={12} />}
              </Button>
            )}
          />
        ))}
      </div>

      {/* Members list */}
      <div className='space-y-px'>
        {members.map((member) => {
          const formId = `member-${member.id}`;
          return (
            <Controller
              key={member.id}
              {...props}
              render={({ field }) => (
                <div className='flex items-center gap-4 py-1 px-2 rounded-lg'>
                  <Label htmlFor={formId}>
                    <Avatar>
                      <AvatarImage src="" alt={member.name} />
                      <AvatarFallback>{member.name[0]}</AvatarFallback>
                    </Avatar>
                  </Label>
                  <Label htmlFor={formId} className='flex flex-col cursor-pointer'>
                    <div>
                      <span className='text-sm leading-4'>{member.name}</span>
                      {isAdmin(member.id) && (
                        <span className='text-xs font-medium text-secondary border-secondary border rounded-md px-2 py-px ml-1'>
                          admin
                        </span>
                      )}
                    </div>
                    <small className='text-xs text-muted-foreground font-normal'>{member.email}</small>
                  </Label>
                  <Checkbox
                    id={formId}
                    aria-readonly={props.isDisabled?.(member.id)}
                    disabled={props.isDisabled?.(member.id)}
                    className='ml-auto'
                    defaultChecked={field.value?.includes(member.id)}
                    checked={field.value?.includes(member.id)}
                    onCheckedChange={(checked) => {
                      const fieldValue = (field.value || []) as string[];
                      return checked
                        ? field.onChange([...fieldValue, member.id])
                        : field.onChange(
                          fieldValue?.filter(
                            (value: string) => value !== member.id
                          )
                        );
                    }}
                  />
                </div>
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

export { MembersFormField };
