import { Button } from '@/components/ui';
import { useUser } from '@/providers/auth';
import { ChevronDownIcon, Cross1Icon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import { AccountPopover } from '../features/company/components/views';
import { Avatar } from '../features/company/components';
import { useUI } from '@/providers/ui';
import { Bell } from 'lucide-react';
import { useNotifications } from '@/lib/hooks/use-notifications';
import { NotificationsPopover } from '@/features/company/components/views/notifications/notifications-popover';

export const AppHeader: React.FC = () => {
  const { user, company } = useUser('AppHeader');
  const { isSideBarOpen, toggleSideBar } = useUI('AppHeader', (state) => state);
  const { unreadCount } = useNotifications('AppHeader');

  return (
    <div className='flex w-full px-4 py-1'>
      {/* <p className="hidden md:block text-sky-950 xl:mx-12 mt-2 ">Overview</p> */}
      <div className='flex items-center gap-4'>
        <Button
          size='icon'
          variant='ghost'
          className='md:hidden text-foreground cursor-pointer'
          onClick={toggleSideBar}
        >
          {!isSideBarOpen ? (
            <HamburgerMenuIcon width={24} height={24} />
          ) : (
            <Cross1Icon width={24} height={24} />
          )}
        </Button>
      </div>
      <div className='flex gap-8 items-center ml-auto'>
        <NotificationsPopover>
          <span className="relative text-muted-foreground cursor-pointer">
            <Bell width={24} height={24} />
            {unreadCount > 0 && (
              <span className="px-1.5 rounded-full absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 flex items-center justify-center text-xs bg-danger text-danger-foreground border border-background">
                {unreadCount}
              </span>
            )}
          </span>
        </NotificationsPopover>
        <AccountPopover>
          <div className='flex items-center gap-2 cursor-pointer'>
            <div className='hidden md:block'>
              <div className='text-base leading-4 font-medium'>{user?.fullName}</div>
              <div className='text-xs capitalize text-muted-foreground'>{company?.name}</div>
            </div>
            <Avatar name={user.fullName} />

            <ChevronDownIcon className='hidden md:block' />
          </div>
        </AccountPopover>
      </div>
    </div>
  );
};

export const MinHeader: React.FC = () => {
  return <></>;
};
