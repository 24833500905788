/* eslint-disable react/prop-types */
import * as React from 'react';
import { Scope, createContextScope } from '@radix-ui/react-context';
import * as PopperPrimitive from '@radix-ui/react-popper';
import { createPopperScope } from '@radix-ui/react-popper';
import type { PopperContentProps } from '@radix-ui/react-popper';
import { useControllableState } from '@/lib/hooks/state';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import * as utils from './utils';
import { Slot } from '@radix-ui/react-slot';
import { DismissableLayer } from '@radix-ui/react-dismissable-layer';
import { FocusScope } from '@radix-ui/react-focus-scope';
import { createCollection } from '@radix-ui/react-collection';

const CALENDAR_NAME = 'Calendar';

type DateItem = {
  date: Date;
  disabled: boolean;
};

const [Collection, useCollection, _createCollectionScope] = createCollection<
  CalendarDayElement,
  DateItem
>(CALENDAR_NAME);
type ScopedProps<P> = P & { __scopeCalendar?: Scope };
const [createCalendarContext, _createCalendarScope] = createContextScope(CALENDAR_NAME, [
  createPopperScope,
]);
const usePopperScope = createPopperScope();

type Setter<S> = S | ((prevState: S | undefined) => S);

type CommonCalendarValue = {
  open?: boolean;
  disabled?: boolean;
  value?: Date;
  defaultValue?: Date | string | number;
  onOpenChange(open: Setter<boolean>): void;
  onValueChange(value: Setter<Date>): void;
};

type CalendarContextValue = CommonCalendarValue & {
  contentId: string;
  trigger: CalendarTriggerElement | null;
  onTriggerChange?: (trigger: CalendarTriggerElement | null) => void;
};

const [CalendarProvider, useCalendarContext] =
  createCalendarContext<CalendarContextValue>(CALENDAR_NAME);

type CalendarProps = {
  children?: React.ReactNode;
  open?: boolean;
  disabled?: boolean;
  value?: Date | null;
  defaultValue?: Date | string | number;
  onOpenChange?(open: Setter<boolean>): void;
  onValueChange?(value: Setter<Date>): void;
};

type CalendarRef = {
  open: () => void;
};

const Calendar = React.forwardRef<CalendarRef, CalendarProps>(
  (props: ScopedProps<CalendarProps>, forwardedRef) => {
    const { __scopeCalendar, disabled = false } = props;

    const popperScope = usePopperScope(__scopeCalendar);
    const [trigger, setTrigger] = React.useState<CalendarTriggerElement | null>(null);
    const [open, setOpen] = useControllableState({
      prop: props.open,
      defaultProp: false,
      onChange: props.onOpenChange,
    });
    const [value, setValue] = useControllableState({
      prop: props.value ?? undefined,
      defaultProp: props.defaultValue ? new Date(props.defaultValue) : undefined,
      onChange: props.onValueChange,
    });

    React.useImperativeHandle(forwardedRef, () => ({
      open: () => setOpen(true),
    }));

    return (
      <PopperPrimitive.Root {...popperScope}>
        <CalendarProvider
          scope={__scopeCalendar}
          contentId={React.useId()}
          disabled={disabled}
          open={open}
          value={value}
          trigger={trigger}
          onTriggerChange={setTrigger}
          onOpenChange={setOpen}
          onValueChange={setValue}
        >
          <Collection.Provider scope={__scopeCalendar}>{props.children}</Collection.Provider>
        </CalendarProvider>
      </PopperPrimitive.Root>
    );
  },
);

Calendar.displayName = CALENDAR_NAME;

// Calendar Trigger
const TRIGGER_NAME = 'CalendarTrigger';

interface CalendarTriggerProps extends React.ComponentPropsWithoutRef<'div'> {
  asChild?: boolean;
}

interface CalendarTriggerElement extends React.ElementRef<'div'> { }

const CalendarTrigger = React.forwardRef<CalendarTriggerElement, CalendarTriggerProps>(
  (__props: ScopedProps<CalendarTriggerProps>, forwardedRef) => {
    const { __scopeCalendar, ...props } = __props;
    const popperScope = usePopperScope(__scopeCalendar);
    const context = useCalendarContext(TRIGGER_NAME, __scopeCalendar);
    const ref = React.useRef<CalendarTriggerElement>(null);
    const composedRef = useComposedRefs(forwardedRef, ref, context.onTriggerChange);
    const isDisabled = context.disabled;

    return (
      <PopperPrimitive.Anchor
        {...popperScope}
        aria-controls={context.contentId}
        aria-expanded={context.open}
        aria-disabled={isDisabled}
        {...props}
        ref={composedRef}
        onClick={(event) => {
          props.onClick?.(event);
          if (!event.isDefaultPrevented()) {
            context.onOpenChange(true);
          }
        }}
      />
    );
  },
);

CalendarTrigger.displayName = TRIGGER_NAME;

// Calendar Content

const CONTENT_NAME = 'CalendarContent';

type CalendarContentElement = CalendarContentImplElement;
interface CalendarContentProps extends CalendarContentImplProps { }

const CalendarContent = React.forwardRef<CalendarContentElement, CalendarContentProps>(
  (props: ScopedProps<CalendarContentProps>, forwardedRef) => {
    const context = useCalendarContext(CONTENT_NAME, props.__scopeCalendar);

    if (!context.open) {
      return null;
    }

    return <CalendarContentImpl {...props} ref={forwardedRef} />;
  },
);

CalendarContent.displayName = CONTENT_NAME;

// Calendar Content Impl

const CONTENT_IMPL_NAME = 'CalendarContentImpl';

type CalendarContentValue = {
  selectedYear: number;
  selectedMonth: number;
  selectedDate?: Date;
  onSelectedYearChange(selectedYear: Setter<number>): void;
  onSelectedMonthChange(selectedMonth: Setter<number>): void;
  onSelectedDateChange(selectedDate: Setter<Date>): void;
  onDateLeave?(): void;
};

const [CalendarContentProvider, useCalendarContentContext] =
  createCalendarContext<CalendarContentValue>(CONTENT_IMPL_NAME);

type CalendarContentImplElement = React.ElementRef<'div'>;
interface CalendarContentImplProps extends PopperContentProps {
  onDateLeave?(): void;
}

const CalendarContentImpl = React.forwardRef<CalendarContentImplElement, CalendarContentImplProps>(
  (__props: ScopedProps<CalendarContentImplProps>, forwardedRef) => {
    const { __scopeCalendar, style, onDateLeave, ...props } = __props;
    const context = useCalendarContext(CONTENT_IMPL_NAME, __scopeCalendar);
    const popperScope = usePopperScope(__scopeCalendar);

    const getItems = useCollection(__scopeCalendar);

    const [selectedYear, setSelectedYear] = React.useState<number>(
      (context.value ?? new Date()).getFullYear(),
    );
    const [selectedMonth, setSelectedMonth] = React.useState<number>(
      (context.value ?? new Date()).getMonth(),
    );
    const [selectedDate, setSelectedDate] = React.useState<Date>(context.value ?? new Date());

    const handleSelectedDateChange = (day: Setter<Date>) => {
      setSelectedDate(day);
    };

    const focusOnDate = React.useCallback((date: Date) => {
      const items = getItems();
      const selectedItem = items.find((item) => utils.isSameDay(item.date, date) && !item.disabled);
      if (selectedItem) {
        selectedItem.ref.current?.focus();
      }
    }, []);

    React.useEffect(() => {
      setTimeout(() => focusOnDate(selectedDate));
    }, [selectedDate]);

    return (
      <CalendarContentProvider
        scope={__scopeCalendar}
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        selectedDate={selectedDate}
        onSelectedYearChange={setSelectedYear}
        onSelectedMonthChange={setSelectedMonth}
        onSelectedDateChange={handleSelectedDateChange}
        onDateLeave={onDateLeave}
      >
        <Collection.Slot scope={__scopeCalendar}>
          <FocusScope
            asChild
            trapped={context.open}
            onMountAutoFocus={(event) => {
              event.preventDefault();
            }}
            onUnmountAutoFocus={(event) => {
              context.trigger?.focus({ preventScroll: true });
              event.preventDefault();
            }}
          >
            <DismissableLayer asChild onDismiss={() => context.onOpenChange(false)}>
              <PopperPrimitive.Content
                {...popperScope}
                sideOffset={2}
                align='center'
                collisionPadding={2}
                {...props}
                style={{
                  // Ensure border-box for floating-ui calculations
                  boxSizing: 'border-box',
                  ...style,
                  // re-namespace exposed content custom properties
                  ...{
                    '--calendar-content-transform-origin': 'var(--radix-popper-transform-origin)',
                    '--calendar-content-available-width': 'var(--radix-popper-available-width)',
                    '--calendar-content-available-height': 'var(--radix-popper-available-height)',
                    '--calendar-trigger-width': 'var(--radix-popper-anchor-width)',
                    '--calendar-trigger-height': 'var(--radix-popper-anchor-height)',
                  },
                }}
                ref={forwardedRef}
                onKeyDown={(event) => {
                  // const isModifierKey =
                  //     event.ctrlKey || event.altKey || event.metaKey;

                  // select should not be navigated using tab key so we prevent it
                  if (event.key === 'Tab') event.preventDefault();

                  if (
                    ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(
                      event.key,
                    )
                  ) {
                    switch (event.key) {
                      case 'ArrowUp':
                        setSelectedDate((date) => utils.minusDays(date, 7));
                        break;
                      case 'ArrowDown':
                        setSelectedDate((date) => utils.addDays(date, 7));
                        break;
                      case 'ArrowLeft':
                        setSelectedDate((date) => utils.minusDays(date, 1));
                        break;
                      case 'ArrowRight':
                        setSelectedDate((date) => utils.addDays(date, 1));
                        break;
                    }

                    // setTimeout(() => focusOnDate());

                    /**
                     * Imperative focus during keydown is risky so we prevent React's batching updates
                     * to avoid potential bugs. See: https://github.com/facebook/react/issues/20332
                     */
                    // setTimeout(() => focusFirst(candidateNodes));

                    event.preventDefault();
                  }
                }}
              />
            </DismissableLayer>
          </FocusScope>
        </Collection.Slot>
      </CalendarContentProvider>
    );
  },
);

CalendarContentImpl.displayName = CONTENT_IMPL_NAME;

// Calendar Days

const DAYS_NAME = 'CalendarDays';

interface CalendarDaysProps {
  children?: React.ReactNode | ((days: Date[]) => React.ReactNode);
}

const CalendarDays: React.FC<CalendarDaysProps> = (__props: ScopedProps<CalendarDaysProps>) => {
  const { __scopeCalendar, children } = __props;
  const context = useCalendarContentContext(DAYS_NAME, __scopeCalendar);

  const days = React.useMemo(() => {
    const days = utils.getDaysInMonth(context.selectedYear, context.selectedMonth);
    return days;
  }, [context.selectedYear, context.selectedMonth]);

  if (typeof children === 'function') {
    return children(days);
  }

  return children;
};

// Calendar Day

const DAY_NAME = 'CalendarDay';

type CalendarDayElement = React.ElementRef<'button'>;
interface CalendarDayProps extends React.ComponentPropsWithoutRef<'button'> {
  date: Date;
  asChild?: boolean;
}

const CalendarDay = React.forwardRef<CalendarDayElement, CalendarDayProps>(
  (__props: ScopedProps<CalendarDayProps>, forwardedRef) => {
    const { __scopeCalendar, date, asChild, ...props } = __props;
    const contentContext = useCalendarContentContext(DAY_NAME, __scopeCalendar);
    const context = useCalendarContext(DAY_NAME, __scopeCalendar);
    const ref = React.useRef<CalendarDayElement>(null);
    const composedRef = useComposedRefs(forwardedRef, ref);

    const isDisabled = context.disabled ?? props.disabled ?? false;
    const isHighlighted =
      contentContext.selectedDate && utils.isSameDay(date, contentContext.selectedDate);
    const isSelected = context.value && utils.isSameDay(date, context.value);
    const isCurrent = utils.isSameDay(date, new Date());
    const isInMonth = utils.isDayInMonth(
      date,
      contentContext.selectedYear,
      contentContext.selectedMonth,
    );

    const Component = asChild ? Slot : 'button';

    return (
      <Collection.ItemSlot scope={__scopeCalendar} date={date} disabled={isDisabled}>
        <Component
          aria-selected={isSelected}
          aria-disabled={isDisabled ? true : undefined}
          data-disabled={isDisabled ? '' : undefined}
          data-selected={isSelected ? '' : undefined}
          data-highlighted={isHighlighted ? '' : undefined}
          data-current={isCurrent ? '' : undefined}
          data-outside={!isInMonth ? '' : undefined}
          tabIndex={isDisabled ? undefined : -1}
          {...props}
          type='button'
          ref={composedRef}
          onClick={(event: any) => {
            props.onClick?.(event);
            if (!event.isDefaultPrevented()) {
              if (!isInMonth) {
                contentContext.onSelectedMonthChange(date.getMonth());
                contentContext.onSelectedYearChange(date.getFullYear());
              }
              context.onValueChange(date);
              context.onOpenChange(false);
            }
          }}
          onMouseEnter={(event: any) => {
            props.onMouseEnter?.(event);
            if (!event.isDefaultPrevented()) {
              contentContext.onSelectedDateChange(date);
            }
          }}
          onMouseLeave={(event: any) => {
            props.onMouseLeave?.(event);
            if (!event.isDefaultPrevented()) {
              contentContext.onDateLeave?.();
            }
          }}
        />
      </Collection.ItemSlot>
    );
  },
);

CalendarDay.displayName = DAY_NAME;

// Calendar Value

const VALUE_NAME = 'CalendarValue';

interface CalendarValueProps extends React.ComponentPropsWithoutRef<'div'> {
  asChild?: boolean;
  transform?: (value: Date) => string;
}
type CalendarValueElement = React.ElementRef<'div'>;

const CalendarValue = React.forwardRef<CalendarValueElement, CalendarValueProps>(
  (__props: ScopedProps<CalendarValueProps>, forwardedRef) => {
    const { __scopeCalendar, asChild, transform = utils.defaultDateToString, ...props } = __props;
    const context = useCalendarContext(VALUE_NAME, __scopeCalendar);

    const Component = asChild ? Slot : 'div';

    return (
      <Component {...props} ref={forwardedRef}>
        {context.value ? transform(context.value) : ''}
      </Component>
    );
  },
);

CalendarValue.displayName = VALUE_NAME;

// Calendar Month Back

const MONTH_BACK_NAME = 'CalendarMonthBack';

interface CalendarMonthBackProps extends React.ComponentPropsWithoutRef<'button'> {
  asChild?: boolean;
}
type CalendarMonthBackElement = React.ElementRef<'button'>;

const CalendarMonthBack = React.forwardRef<CalendarMonthBackElement, CalendarMonthBackProps>(
  (__props: ScopedProps<CalendarMonthBackProps>, forwardedRef) => {
    const { __scopeCalendar, asChild, ...props } = __props;
    const context = useCalendarContentContext(MONTH_BACK_NAME, __scopeCalendar);

    const Component = asChild ? Slot : 'button';

    return (
      <Component
        {...props}
        type='button'
        ref={forwardedRef}
        onClick={(event: any) => {
          props.onClick?.(event);
          if (!event.isDefaultPrevented()) {
            context.onSelectedMonthChange(context.selectedMonth - 1);
          }
        }}
      />
    );
  },
);

CalendarMonthBack.displayName = MONTH_BACK_NAME;

// Calendar Month Forward

const MONTH_FORWARD_NAME = 'CalendarMonthForward';

interface CalendarMonthForwardProps extends React.ComponentPropsWithoutRef<'button'> {
  asChild?: boolean;
}
type CalendarMonthForwardElement = React.ElementRef<'button'>;

const CalendarMonthForward = React.forwardRef<
  CalendarMonthForwardElement,
  CalendarMonthForwardProps
>((__props: ScopedProps<CalendarMonthForwardProps>, forwardedRef) => {
  const { __scopeCalendar, asChild, ...props } = __props;
  const context = useCalendarContentContext(MONTH_FORWARD_NAME, __scopeCalendar);

  const Component = asChild ? Slot : 'button';

  return (
    <Component
      {...props}
      type='button'
      ref={forwardedRef}
      onClick={(event: any) => {
        props.onClick?.(event);
        if (!event.isDefaultPrevented()) {
          context.onSelectedMonthChange(context.selectedMonth + 1);
        }
      }}
    />
  );
});

CalendarMonthForward.displayName = MONTH_FORWARD_NAME;

// Calendar Range Value

const RANGE_VALUE_NAME = 'CalendarRangeValue';

interface CalendarRangeValueProps extends React.ComponentPropsWithoutRef<'div'> {
  asChild?: boolean;
  transform?: (month: number, year: number) => string;
}
type CalendarRangeValueElement = React.ElementRef<'div'>;

const CalendarRangeValue = React.forwardRef<CalendarRangeValueElement, CalendarRangeValueProps>(
  (__props: ScopedProps<CalendarRangeValueProps>, forwardedRef) => {
    const {
      __scopeCalendar,
      asChild,
      transform = utils.defaultCalendarRangeToString,
      ...props
    } = __props;
    const context = useCalendarContentContext(RANGE_VALUE_NAME, __scopeCalendar);

    const Component = asChild ? Slot : 'div';

    return (
      <Component {...props} ref={forwardedRef}>
        {transform(context.selectedMonth, context.selectedYear)}
      </Component>
    );
  },
);

CalendarRangeValue.displayName = RANGE_VALUE_NAME;

// Calendar Icon

const ICON_NAME = 'CalendarIcon';

interface CalendarIconProps extends React.ComponentPropsWithoutRef<'div'> {
  asChild?: boolean;
}
type CalendarIconElement = React.ElementRef<'span'>;

const CalendarIcon = React.forwardRef<CalendarIconElement, CalendarIconProps>(
  (__props: ScopedProps<CalendarIconProps>, forwardedRef) => {
    const { __scopeCalendar, asChild, ...props } = __props;
    // const context = useCalendarContext(ICON_NAME, __scopeCalendar);

    const Component = asChild ? Slot : 'span';

    return <Component {...props} ref={forwardedRef} />;
  },
);

CalendarIcon.displayName = ICON_NAME;

export const Root = Calendar;
export const Trigger = CalendarTrigger;
export const Value = CalendarValue;
export const Content = CalendarContent;
export const Days = CalendarDays;
export const Day = CalendarDay;
export const MonthBack = CalendarMonthBack;
export const MonthForward = CalendarMonthForward;
export const RangeValue = CalendarRangeValue;
export const Icon = CalendarIcon;

export type { CalendarRef, CalendarProps };
