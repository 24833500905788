import { Outlet, RouteObject } from 'react-router-dom';
import { AppLayout, PlainLayout, PlainLayoutProps } from '@/components/layouts';
import { SuspenseWithPerf } from 'reactfire';
import { AuthWrapper, UserWrapper } from '@/providers/auth';
import { UIProvider } from '@/providers/ui';
import { lazyImport } from '@/lib/utils';
import { Spinner } from '@/components/icons';
import { Suspense } from 'react';
import { ErrorPage } from '@/features/misc/error-page';
import { Sidebar } from './components/sidebar';
import { LawyerOnboarding } from './pages/lawyer-onboarding';

const Protected = ({ children }: { children: React.ReactNode }) => {
  return (
    <SuspenseWithPerf fallback={<h1>Loading...</h1>} traceId='vega-user-wait'>
      <AuthWrapper>
        <UserWrapper>
          {children}
        </UserWrapper>
      </AuthWrapper>
    </SuspenseWithPerf>
  );
};

const fallback = (
  <div className='h-screen grid place-items-center'>
    <Spinner size={28} />
  </div>
);

const Plain = (props: PlainLayoutProps & { noRedirect?: boolean }) => {
  return (
    <SuspenseWithPerf fallback={<h1>Loading...</h1>} traceId='vega-user-wait'>
      <AuthWrapper>
        <UserWrapper noRedirect={props.noRedirect}>
          <PlainLayout {...props}>
            <Outlet />
          </PlainLayout>
        </UserWrapper>
      </AuthWrapper>
    </SuspenseWithPerf>
  );
};

const App = () => {
  return (
    <Protected>
      <UIProvider>
        <AppLayout sidebar={<Sidebar />}>
          <Suspense fallback={fallback}>
            <Outlet />
          </Suspense>
        </AppLayout>
      </UIProvider>
    </Protected>
  );
};

const { Dashboard } = lazyImport(() => import('@/features/lawyer/pages'), 'Dashboard');
const { Calendar } = lazyImport(() => import('@/features/lawyer/pages'), 'Calendar');
// const { Clients } = lazyImport(() => import('@/features/lawyer/pages'), 'Clients');
const { Profile } = lazyImport(() => import('@/features/lawyer/pages'), 'Profile');
const { Tasks } = lazyImport(() => import('@/features/lawyer/pages'), 'Tasks');

export const lawyerRoutes: RouteObject[] = [
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <Dashboard /> },
      {
        path: '/calendar',
        element: <Calendar />,
      },
      // {
      //   path: '/clients',
      //   element: <Clients />,
      // },
      {
        path: '/tasks',
        element: <Tasks />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: 'onboarding',
    element: <Plain noRedirect />,
    children: [{ index: true, element: <LawyerOnboarding /> }],
  },
];
