import { cn } from '@/lib/utils';
import * as P from '@radix-ui/react-popover';
import { forwardRef } from 'react';

const Content = forwardRef<HTMLDivElement, P.PopoverContentProps>((props, ref) => (
  <P.Content
    ref={ref}
    collisionPadding={8}
    sideOffset={8}
    {...props}
    className={cn(
      'px-4 py-2 bg-background rounded-xl shadow-md border border-stroke/10',
      props.className,
    )}
  />
));

Content.displayName = 'PopoverContent';

export const Popover = {
  ...P,
  Content,
};
