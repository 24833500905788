import { Outlet, RouteObject } from 'react-router-dom';
import { AppLayout, PlainLayout, PlainLayoutProps } from '@/components/layouts';
import { SuspenseWithPerf } from 'reactfire';
import { AuthWrapper, UserWrapper } from '@/providers/auth';
import { UIProvider } from '@/providers/ui';
import { lazyImport } from '@/lib/utils';
import { Spinner } from '@/components/icons';
import { Suspense } from 'react';
import { ACL } from '@/features/company/components';
import { AccessDenied } from '@/components/ui';
import { ErrorPage } from '@/features/misc/error-page';
import { Sidebar } from './components/sidebar';

const Protected = ({ children }: { children: React.ReactNode }) => {
  return (
    <SuspenseWithPerf fallback={<h1>Loading...</h1>} traceId='vega-user-wait'>
      <AuthWrapper>
        <UserWrapper>
          {children}
        </UserWrapper>
      </AuthWrapper>
    </SuspenseWithPerf>
  );
};

const fallback = (
  <div className='h-screen grid place-items-center'>
    <Spinner size={28} />
  </div>
);

const App = () => {
  return (
    <Protected>
      <UIProvider>
        <AppLayout sidebar={<Sidebar />}>
          <Suspense fallback={fallback}>
            <Outlet />
          </Suspense>
        </AppLayout>
      </UIProvider>
    </Protected>
  );
};


const Plain = (props: PlainLayoutProps & { noRedirect?: boolean }) => {
  return (
    <SuspenseWithPerf fallback={<h1>Loading...</h1>} traceId='vega-user-wait'>
      <AuthWrapper>
        <UserWrapper noRedirect={props.noRedirect}>
          <PlainLayout {...props}>
            <Outlet />
          </PlainLayout>
        </UserWrapper>
      </AuthWrapper>
    </SuspenseWithPerf>
  );
};

const { Account } = lazyImport(() => import('@/features/company/pages'), 'Account');
const { Advice } = lazyImport(() => import('@/features/company/pages'), 'Advice');
const { Agreements } = lazyImport(() => import('@/features/company/pages'), 'Agreements');
const { Draft } = lazyImport(() => import('@/features/company/pages'), 'Draft');
const { File } = lazyImport(() => import('@/features/company/pages'), 'File');
const { Files } = lazyImport(() => import('@/features/company/pages'), 'Files');
const { Home } = lazyImport(() => import('@/features/company/pages'), 'Home');
const { Member } = lazyImport(() => import('@/features/company/pages'), 'Member');
const { AcceptInvite } = lazyImport(() => import('@/features/company/pages'), 'AcceptInvite');
const { OnboardingStep1 } = lazyImport(() => import('@/features/company/pages'), 'OnboardingStep1');
const { OnboardingStep2 } = lazyImport(() => import('@/features/company/pages'), 'OnboardingStep2');
const { Lawyers } = lazyImport(() => import('@/features/company/pages'), 'Lawyers');
const { Payment } = lazyImport(() => import('@/features/company/pages'), 'Payment');
const { Tasks } = lazyImport(() => import('@/features/company/pages'), 'Tasks');
const { Tickets } = lazyImport(() => import('@/features/company/pages'), 'Tickets');
const { Approval } = lazyImport(() => import('@/features/company/pages'), 'Approval');
const { Team } = lazyImport(() => import('@/features/company/pages'), 'Team');
const { Shared } = lazyImport(() => import('@/features/company/pages'), 'Shared');
const { Roles } = lazyImport(() => import('@/features/company/pages'), 'Roles');
const { Custom } = lazyImport(() => import('@/features/company/pages'), 'Custom');
const { AddNewCompany } = lazyImport(() => import('@/features/company/pages'), 'AddNewCompany');

const { NewTicketModalView } = lazyImport(() => import('@/features/company/components/views/tickets/new-ticket-modal'), 'NewTicketModalView');
const { ViewTicketModal } = lazyImport(() => import('@/features/company/components/views/tickets/view-ticket-modal'), 'ViewTicketModal');
const { EditTicketModal } = lazyImport(() => import('@/features/company/components/views/tickets/edit-ticket-modal'), 'EditTicketModal');
const { MoveTicketModal } = lazyImport(() => import('@/features/company/components/views/tickets/move-ticket-modal'), 'MoveTicketModal');
const { DeleteTicketModal } = lazyImport(() => import('@/features/company/components/views/tickets/delete-ticket-modal'), 'DeleteTicketModal');

const { NewBookingModal } = lazyImport(() => import('@/features/company/components/views/lawyers/new-booking-modal'), 'NewBookingModal');
const { CallbackBookingModal } = lazyImport(() => import('@/features/company/components/views/lawyers/callback-booking-modal'), 'CallbackBookingModal');
const { ScheduleBookingModal } = lazyImport(() => import('@/features/company/components/views/lawyers/schedule-booking-modal'), 'ScheduleBookingModal');

export const companyRoutes: RouteObject[] = [
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'agreements',
        element: (
          <ACL permission='admin.agreement:view' fallback={<AccessDenied />}>
            <Agreements />
          </ACL>
        ),
      },
      {
        path: 'tasks',
        element: (
          <ACL permission={'admin.task:view'} fallback={<AccessDenied />}>
            <Tasks />
          </ACL>
        ),
      },
      {
        path: 'roles',
        element: (
          <ACL permission='admin.role:view' fallback={<AccessDenied />}>
            <Roles />
          </ACL>
        ),
      },
      {
        path: 'approval',
        element: (
          <ACL permission='admin.agreement:view' fallback={<AccessDenied />}>
            <Approval />
          </ACL>
        ),
      },
      {
        path: 'files',
        children: [
          {
            path: '',
            element: (
              <ACL permission='admin.file:view' fallback={<AccessDenied />}>
                <Files />
              </ACL>
            ),
          },
          {
            path: ':id',
            element: (
              <ACL permission='admin.file:view' fallback={<AccessDenied />}>
                <Files />
              </ACL>
            ),
          },
        ],
      },
      {
        path: 'advice',
        element: (
          <ACL permission='admin.legal:view' fallback={<AccessDenied />}>
            <Advice />
          </ACL>
        ),
      },
      {
        path: 'experts',
        element: (
          <ACL permission='admin.legal:view' fallback={<AccessDenied />}>
            <Lawyers />
          </ACL>
        ),
        children: [
          {
            path: ':lawyerId/new-booking',
            element: (
              <ACL permission='admin.legal:view' fallback={<AccessDenied />}>
                <NewBookingModal />
              </ACL>
            ),
          },
          {
            path: 'payment-callback',
            element: (
              <ACL permission='admin.legal:view' fallback={<AccessDenied />}>
                <CallbackBookingModal />
              </ACL>
            ),
          },
          {
            path: 'schedule/:bookingId',
            element: (
              <ACL permission='admin.legal:view' fallback={<AccessDenied />}>
                <ScheduleBookingModal />
              </ACL>
            ),
          }
        ]
      },
      {
        path: 'custom',
        element: <Custom />,
      },
      {
        path: 'tickets',
        element: <Tickets />,
        children: [
          {
            path: 'new',
            element: <NewTicketModalView />,
          },
          {
            path: ':ticketId',
            element: <ViewTicketModal />,
          },
          {
            path: 'edit/:ticketId',
            element: <EditTicketModal />,
          },
          {
            path: 'move/:ticketId',
            element: <MoveTicketModal />,
          },
          {
            path: 'delete/:ticketId',
            element: <DeleteTicketModal />,
          },
        ],
      },
      // { path: 'approval', element: <Approval /> },
      {
        path: 'members',
        children: [
          {
            path: '',
            element: (
              <ACL permission='admin.team:view' fallback={<AccessDenied />}>
                <Team />
              </ACL>
            ),
          },
          {
            path: ':memberId',
            element: (
              <ACL permission='admin.team:view' fallback={<AccessDenied />}>
                <Member />
              </ACL>
            ),
          },
        ],
      },
      {
        path: 'account',
        element: (
          <ACL fallback={<AccessDenied />} permission='admin.account:view'>
            <Account />
          </ACL>
        ),
        children: [
          { path: 'settings', index: true, element: <h1>Settings</h1> },
          { path: 'plan', element: <h1>Plan</h1> },
          { path: 'change-password', element: <h1>Change Password</h1> },
          { path: 'profile', element: <h1>Profile</h1> },
          { path: 'preferences', element: <h1>Notifications</h1> },
        ],
      },
    ],
  },
  {
    element: <Plain noRedirect />,
    children: [
      { path: 'onboarding', element: <OnboardingStep1 /> },
      { path: 'onboarding/2', element: <OnboardingStep2 /> },
      { path: 'new-company', element: <AddNewCompany /> },
      { path: 'invite/:invitationId', element: <AcceptInvite /> },
    ],
  },
  {
    element: <Plain className='max-w-4xl' goBack />,
    children: [
      { path: 'payment', element: <Payment /> },
    ],
  },
  {
    element: (
      <Protected>
        <Outlet />
      </Protected>
    ),
    children: [
      { path: 'draft/:id', element: <Draft /> },
      { path: 'draft/:id/:step', element: <Draft /> },
      { path: 'file/:fileId', element: <File /> },
    ],
  },
  {
    element: <Outlet />,
    children: [
      {
        path: 'shared/:companyId/:shareId',
        element: <Shared />,
      },
    ],
  },
];
