import { Permission } from '@/data/permission';
import { useUser } from '@/providers/auth';
import { Fragment, useMemo } from 'react';
import { useRoles } from '../hooks/use-roles';

type ACLProps = {
  permission: Permission | Permission[];
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export const ACL = ({
  permission,
  children,
  fallback
}: ACLProps) => {
  const { user, company } = useUser('ACL');
  const roles = useRoles('ACL');

  const requiredPermissions = useMemo(() => {
    return Array.isArray(permission) ? permission : [permission];
  }, [permission]);

  const permissions = useMemo(() => {
    if (!company.members?.[user.uid]) {
      return [];
    }

    const roleId = company.members[user.uid].role ?? 'member';
    const role = roles.getRole(roleId);
    return role?.permissions || [];
  }, [company.members, user.uid]);

  const hasPermission = useMemo(() => {
    return requiredPermissions?.some((p) => {
      const wildcardPermission = p.split(':')[0] + ':*';
      return permissions?.includes(p) || permissions?.includes(wildcardPermission);
    });
  }, [permissions, permission]);

  return (
    <Fragment>
      {hasPermission ? children : fallback ?? null}
    </Fragment>
  );
};
