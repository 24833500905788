import { cn } from '@/lib/utils';
// import vegaPng from '@/assets/vega.png';
import vegaPng from '@/assets/luv2.png';

type LogoProps = {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
};

export const VegaIcon = ({ className, size }: LogoProps) => (
  <span className={cn(' justify-center items-center', className)}>
    <img src={vegaPng} className={cn('w-auto', {
      'h-9': size === 'sm',
      'h-12': size === 'md',
      'h-16': size === 'lg',
    })} alt='logo' />
    {/* <img src={vegaPng} className='w-[90%] md:w-auto' alt='logo' /> */}
  </span>
);


// export const VegaIcon = ({ className, size = 'md' }: LogoProps) => (
//   <span className={cn('inline-flex gap-3 justify-center items-center', className)}>
//     <img src={vegaPng} className='w-9 aspect-square' alt='logo' />
//     <h2
//       className={cn({
//         'text-lg font-medium tracking-tighter': size === 'sm',
//         'text-xl font-medium tracking-tighter': size === 'md',
//         'text-3xl font-medium tracking-tighter': size === 'lg',
//       })}
//     >
//       Vega
//     </h2>
//   </span>
// );
