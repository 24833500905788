import Handlebars from 'handlebars';

export default class Templater {
  private template: HandlebarsTemplateDelegate;

  constructor(template: string, options?: CompileOptions) {
    this.registerHelpers();
    this.template = Handlebars.compile(template, options);
  }

  public render(data: object, options?: Handlebars.RuntimeOptions): string {
    return this.template(data, options);
  }

  private registerHelpers(): void {
    Handlebars.registerHelper('ordinal', (index: number) => {
      if (typeof index !== 'number') {
        return '';
      }
      index++; // Handlebars uses 0-based indexing
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const remainder = index % 100;
      return `${index}${suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0]}`;
    });

    Handlebars.registerHelper('ifCond', (v1, operator, v2, options) => {
      switch (operator) {
        case '==':
          return v1 == v2 ? options.fn(this) : options.inverse(this);
        case '===':
          return v1 === v2 ? options.fn(this) : options.inverse(this);
        case '!=':
          return v1 != v2 ? options.fn(this) : options.inverse(this);
        case '!==':
          return v1 !== v2 ? options.fn(this) : options.inverse(this);
        case '<':
          return v1 < v2 ? options.fn(this) : options.inverse(this);
        case '<=':
          return v1 <= v2 ? options.fn(this) : options.inverse(this);
        case '>':
          return v1 > v2 ? options.fn(this) : options.inverse(this);
        case '>=':
          return v1 >= v2 ? options.fn(this) : options.inverse(this);
        case '&&':
          return v1 && v2 ? options.fn(this) : options.inverse(this);
        case '||':
          return v1 || v2 ? options.fn(this) : options.inverse(this);
        default:
          return options.inverse(this);
      }
    });

    Handlebars.registerHelper('possessive', (name: string) => {
      // to use: {{possessive name}}

      if (!name) {
        return '';
      }
      if (name.endsWith('s')) {
        return `${name}'`;
      }
      return `${name}'s`;
    });

    Handlebars.registerHelper('lookup', (collection, index) => {
      return collection && collection[index];
    });

      // Registering comparison helpers
      Handlebars.registerHelper('eq', (a, b) => a === b);
      Handlebars.registerHelper('neq', (a, b) => a !== b);
      Handlebars.registerHelper('equal', (a, b) => a === b);
      Handlebars.registerHelper('add', (a, b) => a + b);
      Handlebars.registerHelper('subtract', (a, b) => a - b);
      Handlebars.registerHelper('or', function() {
        return Array.prototype.slice.call(arguments, 0, -1)?.some(Boolean);
    });
    
  }
}

export const renderTemplate = (template: string, data: object): string => {
  const templater = new Templater(template, { noEscape: true });
  // to prevent escape characters from being rendered use options:
  return templater.render(data);
};
