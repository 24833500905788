import { Status } from '@/lib/definitions';

export const defaultAgreementStatus = [
  {
    label: 'draft',
    color: '#e8ecee',
  },
  {
    label: 'pending',
    color: '#FFB800',
  },
  {
    label: 'signed',
    color: '#a0c3ff',
  },
  {
    label: 'declined',
    color: '#ff6b78',
  },
  {
    label: 'completed',
    color: '#64C2A6',
  }
] as Status[];
