import { Status } from '@/lib/definitions';
import { cn, colorToLightness } from '@/lib/utils';
import { forwardRef, useMemo } from 'react';
import { Button, ButtonProps } from '../../../components/ui';
import { defaultAgreementStatus } from '@/data/statuses';

type StatusPillProps = ButtonProps & {
  status?: Status;
  noBg?: boolean;
};

export const StatusPill = forwardRef<HTMLButtonElement, StatusPillProps>(
  ({ status: statusProps, className, children, noBg = false, ...props }, ref) => {

    const status = useMemo(() => {
      if (statusProps) return statusProps;
      return defaultAgreementStatus[0];
    }, [statusProps]);

    const style = useMemo(() => {
      const backgroundColor = status?.color ?? 'rgba(var(--primary) / 0.1)';
      const color =
        colorToLightness(backgroundColor) === 'black'
          ? 'rgba(var(--foreground) / 0.8)'
          : 'rgba(var(--background) / 0.8)';
      return {
        '--status-bg': backgroundColor,
        '--status-color': noBg ? backgroundColor : color,
      } as React.CSSProperties;
    }, [status?.color, noBg]);

    return (
      <Button asChild variant='mid' style={{ border: noBg ? 0 : 1 }} {...props} ref={ref}>
        <span
          style={style}
          className={cn(
            'py-1 px-4 flex items-center justify-center capitalize rounded-lg',
            'text-[var(--status-color)]',
            noBg ? 'bg-transparent' : 'bg-[var(--status-bg)]',
            className,
          )}
        >
          {status.label}
          {children}
        </span>
      </Button>
    );
  },
);

StatusPill.displayName = 'StatusPill';
