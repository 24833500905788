import { Button, Input, Modal, TagInput } from '@/components/ui';
import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import { FileTextIcon } from '@radix-ui/react-icons';
import { useControllableState } from '@/lib/hooks/state';
import { usePromise, useYupValidationResolver } from '@/lib/hooks';
import { VegaFile } from '@/lib/definitions';
import { isEmail } from 'validator';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { useUser } from '@/providers/auth';
import * as yup from 'yup';

type ShareFileModalProps = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  file: VegaFile;
  path?: string;
};

const schema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  cc: yup.array().of(yup.string().email().required()).optional(),
});

type DTO = yup.InferType<typeof schema>;

export const ShareFileModal = (props: ShareFileModalProps) => {
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });
  const { company } = useUser('ShareFileModal');
  const resolver = useYupValidationResolver(schema);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm<DTO>({ resolver });
  const functions = useFunctions();

  const [share, isSubmitting, _token, _error, status, resetPromise] = usePromise(
    async (data: DTO) => {
      const shareFile = httpsCallable(functions, 'shareFile');

      const token = await shareFile({
        fileId: props.file.id,
        email: data.email,
        companyId: company.id,
        cc: data.cc,
        path: props.path,
      });
      return token;
    },
  );

  const submit = handleSubmit((data) => {
    share(data);
  });

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      reset();
      resetPromise();
    }
    onOpenChange(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      {props.children && <Modal.Trigger asChild>{props.children}</Modal.Trigger>}
      <Modal.Content className='max-w-xl space-y-2'>
        <header className='px-8 py-8 sticky top-0 bg-background z-10'>
          <div className='flex gap-4 items-center'>
            <FileTextIcon width={28} height={28} className='text-primary' />
            <Modal.Title>Share with external party</Modal.Title>
          </div>
        </header>
        {status === 'success' ? (
          <div className='px-8 space-y-12'>
            <Modal.Description>
              The file has been shared with the recipient. They will receive an email with a link to
              the document.
            </Modal.Description>
            <div className='flex justify-end space-x-2 mt-4 sticky bottom-0 bg-background py-6 border-t border-muted'>
              <Modal.Close asChild>
                <Button size='lg' variant='outline'>
                  Close
                </Button>
              </Modal.Close>
            </div>
          </div>
        ) : (
          <form className='px-8 space-y-12' onSubmit={submit}>
            <div className='flex flex-col gap-4'>
              <Modal.Description>
                Share the document with an external party. Recipients can leave comments, respond to
                them and suggest changes to the document.
              </Modal.Description>
              <Input
                label='Email'
                placeholder='Enter email address'
                type='email'
                {...register('email')}
                error={errors.email?.message}
              />
              <Controller
                name='cc'
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <TagInput
                    label='CC'
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    placeholder='Add email'
                    error={errors.cc?.message}
                    onValidate={isEmail}
                  />
                )}
              />
            </div>
            <div className='flex justify-end space-x-2 mt-4 sticky bottom-0 bg-background py-6 border-t border-muted'>
              <Modal.Close asChild>
                <Button size='lg' variant='outline'>
                  Cancel
                </Button>
              </Modal.Close>
              <Button size='lg' type='submit' loading={isSubmitting}>
                Share
              </Button>
            </div>
          </form>
        )}
      </Modal.Content>
    </Modal.Root>
  );
};
