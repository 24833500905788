import WebViewer, { Core, type WebViewerInstance } from '@pdftron/webviewer';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { APRYSE_LICENSE } from '@/lib/config';
import { convertBlobToBase64, getBlobFromAnnotation } from '@/lib/apryse';

type VegaWebViewerProps = {
  filename?: string;
  downloadUrl: string;
  isEditing: boolean;
  onSave?: (blob: Blob) => void;
  onChange?: () => void;
  signatures?: string[];
  onAddSignature?: (blob: Blob) => void;
  editorName?: string
};

export type VegaWebViewerRef = {
  download: () => void;
};

export const VegaWebViewer = forwardRef<VegaWebViewerRef, VegaWebViewerProps>(
  ({
    filename,
    downloadUrl,
    isEditing,
    onSave,
    onChange,
    onAddSignature,
    editorName,
    signatures = [],
  }, ref) => {
    const viewer = useRef<HTMLDivElement>(null);
    const webviewer = useRef<WebViewerInstance>();
    const [signatureTool, setSignatureTool] = useState<Core.Tools.SignatureCreateTool>();
    const [isSignatureToolReady, setIsSignatureToolReady] = useState(false);

    useImperativeHandle(ref, () => ({
      download: () => {
        if (!webviewer.current) return;
        webviewer.current.UI.downloadPdf({
          filename: filename ?? 'Document',
          includeAnnotations: true,
        });
      },
    }));

    useEffect(() => {
      if (!viewer.current || !downloadUrl) return;

      // Prevent multiple instances of webviewer
      if (webviewer.current) return;

      const documentName = filename ?? 'Document';
      const isOfficeDocument = filename?.endsWith('.docx') || filename?.endsWith('.doc');

      const disabledElements = [
        // 'leftPanel',
        // 'viewControlsButton',
        // 'viewControlsOverlay',
        'menuButton',
        'menuOverlay',
        // 'annotationPopup',
        // 'annotationCommentButton',
        // 'annotationStyleEditButton',
        // 'annotationStyleEditOverlay',
        // 'annotationStyleEditPopup',
        // 'annotationStyleEditColorPalette',
        // 'annotationStyleEditOpacitySlider',
        // 'annotationStyleEditThicknessSlider',
        // 'zoomOverlayButton',
        'viewControlsButton',
        'viewControlsOverlay',
        // 'searchListener',
        // 'panToolButton',
        // 'leftPanelButton',
        // 'toggleNotesButton',
        'selectToolButton',
        // 'toolbarGroup-View',
        'toolbarGroup-Annotate',
        'toolbarGroup-Shapes',
        'toolbarGroup-Insert',
        'toolbarGroup-Edit',
        'toolbarGroup-Forms',
        // 'rubberStampToolGroupButton',
        'undoButton',
        'redoButton',
        // 'eraserToolButton',

        // unwanted tools
        'officeEditorFileName',
        'contextMenuPopup',

        // Prevent shared document from being edited
        // 'dropdown-item-editing',
      ];

      WebViewer(
        {
          path: '/webviewer',
          licenseKey: APRYSE_LICENSE ?? '',
          config: '/config.js',
          disabledElements,
          annotationUser: editorName ?? 'Guest',
          enableAnnotations: isEditing,
          filename: documentName,
        },
        viewer.current,
      ).then(async (instance: WebViewerInstance) => {
        instance.UI.setLayoutMode(instance.UI.LayoutMode.Continuous);

        const { documentViewer, annotationManager } = instance.Core;

        documentViewer.addEventListener('documentLoaded', () => {
          const signatureTool = documentViewer.getTool(
            'AnnotationCreateSignature',
          ) as Core.Tools.SignatureCreateTool;
          setSignatureTool(signatureTool);
          documentViewer.setFitMode(documentViewer.FitMode.FitWidth);

          documentViewer.addEventListener('keyUp', () => {
            onChange?.();
            saveDocument();
          });

          annotationManager.addEventListener('annotationChanged', (_annotations, _action) => {
            onChange?.();
            saveDocument();
          });
        });

        documentViewer.loadDocument(downloadUrl, {
          enableOfficeEditing: isEditing && isOfficeDocument,
        });

        webviewer.current = instance;
      });
    }, [viewer.current, downloadUrl, isEditing]);

    useEffect(() => {
      if (!signatureTool || !webviewer.current) return;

      const documentViewer = webviewer.current.Core.documentViewer;

      const signatureReadyListener = async (annotation: Core.Annotations.Annotation) => {
        const blob = await getBlobFromAnnotation(annotation, documentViewer);
        if (blob) {
          onAddSignature?.(blob);
        }
      };

      signatureTool.addEventListener('signatureReady', signatureReadyListener);

      return () => {
        signatureTool?.removeEventListener('signatureReady', signatureReadyListener);
      };
    }, [signatureTool, onAddSignature]);

    const importSignatures = useCallback(async () => {
      if (!webviewer.current || signatures.length == 0 || !signatureTool) return;
      if (isSignatureToolReady) return;

      const getBlob = async (url: string) => {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const base64 = await convertBlobToBase64(blob);
          return base64;
        } catch (err) {
          console.error(err);
          return null;
        }
      };

      const signatureBase64s = await Promise.all(signatures.map(getBlob));
      const nonNullBase64s = signatureBase64s.filter(Boolean) as string[];
      await signatureTool.importSignatures(nonNullBase64s);

      setIsSignatureToolReady(true);
    }, [signatures, isSignatureToolReady, signatureTool]);

    useEffect(() => {
      importSignatures();
    }, [importSignatures]);

    const saveDocument = useDebouncedCallback(async () => {
      if (!webviewer.current || !downloadUrl) return;
      if (!isEditing) return;
      const doc = webviewer.current.Core.documentViewer.getDocument();
      const xfdfString = await webviewer.current.Core.annotationManager?.exportAnnotations();
      if (!doc || !xfdfString) return;
      const data = await doc.getFileData({
        xfdfString, // saves the document with annotations in it
      });
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      onSave?.(blob);
    }, 3000);

    return <div ref={viewer} className='shadow-sm rounded-lg h-full' />;
  }
);

VegaWebViewer.displayName = 'VegaWebViewer';

