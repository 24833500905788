import { Spinner } from '@/components/icons';
import { createContext } from '@/lib/context';
import type { Company, Lawyer, User } from '@/lib/definitions';
import useProduct from '@/lib/hooks/use-product';
import { ref } from 'firebase/database';
import { Navigate, useLocation } from 'react-router-dom';
import { useDatabase, useDatabaseObjectData, useSigninCheck, useUser } from 'reactfire';

type AuthContextValue = {
  user: User | null;
  company: Company | null;
  lawyer: Lawyer | null;
};

const [use, Provider] = createContext<AuthContextValue>('AuthProvider');

const LoadingScreen = () => (
  <div className='h-screen grid place-items-center'>
    <Spinner />
  </div>
);

const ErrorScreen = () => (
  <div className='h-screen grid place-items-center'>
    <h1>Error :)</h1>
  </div>
);

/**
 * Provider for the company data
 * @param user
 * @param children
 */
const CompanyProvider = ({
  user,
  children,
}: {
  user: User;
  children: (company: Company | null, status: string) => JSX.Element;
}) => {
  const { data: auth } = useUser();
  const db = useDatabase();

  if (!user.activeCompanyId) {
    return children(null, 'success');
  }

  const { status, data: company } = useDatabaseObjectData<Company>(
    ref(db, `companies/${user.activeCompanyId}`),
    {
      // @ts-expect-error Hack for login crash
      idField: `id-${auth?.stsTokenManager.expirationTime}`,
    },
  );

  return children(company, status);
};

/**
 * Provider for the lawyer data
 * @param children
 */
const LawyerProvider = ({
  children,
}: {
  children: (lawyer: Lawyer | null, status: string) => JSX.Element;
}) => {
  const { data: auth } = useUser();
  const db = useDatabase();

  const { status, data: lawyer } = useDatabaseObjectData<Lawyer>(
    ref(db, `lawyers/${auth?.uid}`),
    {
      // @ts-expect-error Hack for login crash
      idField: `id-${auth?.stsTokenManager.expirationTime}`,
    },
  );

  return children(lawyer, status);
};

/**
 * Wrapper for the user data
 * @param children
 * @param user
 * @param noRedirect
 */
const UserWrapperInner = ({
  children,
  user,
  noRedirect = false,
}: {
  children: React.ReactNode;
  user: User;
  noRedirect?: boolean;
}) => {
  const { pathname } = useLocation();
  const product = useProduct();

  return (
    <CompanyProvider user={user}>
      {(company, companyStatus) => (
        <LawyerProvider>
          {(lawyer, lawyerStatus) => {
            if (companyStatus === 'loading' || lawyerStatus === 'loading') {
              return <LoadingScreen />;
            }

            if (companyStatus === 'error' || lawyerStatus === 'error') {
              return <ErrorScreen />;
            }

            if (!company && product === 'vega-company' && !noRedirect) {
              return <Navigate to="/onboarding" state={{ from: pathname }} replace />;
            }

            if (!lawyer && product === 'vega-lawyer' && !noRedirect) {
              return <Navigate to="/onboarding" state={{ from: pathname }} replace />;
            }

            return (
              <Provider user={user} company={company} lawyer={lawyer}>
                {children}
              </Provider>
            );
          }}
        </LawyerProvider>
      )}
    </CompanyProvider>
  );
};

/**
 * Wrapper for the user data
 * @param children
 * @param noRedirect
 */
const UserWrapper = (props: { children: React.ReactNode, noRedirect?: boolean }) => {
  const { data: auth } = useUser();
  const { pathname } = useLocation();
  const db = useDatabase();
  const { status, data: user } = useDatabaseObjectData<User>(ref(db, `users/${auth?.uid}`), {
    // @ts-expect-error this is a hack to prevent crash when a user is logged out and has just logged in
    idField: `id-${auth?.stsTokenManager.expirationTime}`,
  });

  if (status === 'loading') {
    return <LoadingScreen />;
  }

  if (status === 'error') {
    return <ErrorScreen />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: pathname }} replace />;
  }

  return <UserWrapperInner user={user!} {...props} />;
};

/**
 * Wrapper for the authentication
 * @param children
 */
const AuthWrapper = ({ children }: React.PropsWithChildren) => {
  const { pathname } = useLocation();
  const { status, data: signInCheckResult } = useSigninCheck({ suspense: true });

  if (!children) {
    throw new Error('Children must be provided');
  }

  if (status === 'loading') {
    return <LoadingScreen />;
  }

  if (status === 'error') {
    return <ErrorScreen />;
  }

  if (signInCheckResult.signedIn === true) {
    return children as JSX.Element;
  } else {
    return <Navigate to="/login" state={{ from: pathname }} replace />;
  }
};

/**
 * Hook to get the user data
 * @param componentName
 * @returns
 */
const useDefinedUser = (componentName: string) => {
  const { user, company, lawyer } = use(componentName);
  return {
    user: user!,
    company: company!,
    lawyer: lawyer!,
  };
};

export { useDefinedUser as useUser, use as Unsafe_useUser, UserWrapper, AuthWrapper };
