import { Button, Modal, withModal } from '../../../../../components/ui';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { doc } from 'firebase/firestore';
import { Doc, UserRef, VegaFile } from '@/lib/definitions';
import { useUser } from '@/providers/auth';
import { useForm } from 'react-hook-form';
import { usePromise } from '@/lib/hooks';
import { manageAccessAlert, setFileAccess } from '@/actions/files';
import { Lock } from 'lucide-react';
import { MembersFormField } from '../../members-form-field';

type ManageFileAccessFormProps = {
  file: VegaFile;
  close: () => void;
};

interface DTO {
  users: string[];
}

const ManageFileAccessForm = ({ file, close }: ManageFileAccessFormProps) => {
  const { company, user } = useUser('ManageFileAccessForm');

  const form = useForm<DTO>({
    defaultValues: async () => {
      if (file.accessBy && file.accessBy.length > 0) {
        return {
          users: file.accessBy.map((user) => user.id),
        };
      }

      // All user have access if no accessBy
      return {
        users: company?.members ? Object.keys(company.members) : [],
      };
    }
  });

  const [changeAccess, isChangingAccess] = usePromise(
    form.handleSubmit(async ({ users = [] }) => {
      const accessBy = [] as UserRef[];

      new Set(users).forEach((id) => {
        const member = company?.members?.[id];
        if (member) {
          accessBy.push({
            id: member.id,
            name: member.name,
            email: member.email
          });
        }
      });

      await setFileAccess(company.id, file.id, accessBy);

      const newUsers = accessBy.filter(
        (user) => !file.accessBy?.some((existingUser) => existingUser.id === user.id),
      );

      const targetEmails = newUsers.map((user) => user.email);
      if (targetEmails.length > 0) {
        await manageAccessAlert({
          docId: file.id,
          documentName: file.name,
          targetEmails: targetEmails,
          type: file.type
        });
      }

      form.reset({
        users: accessBy.map((user) => user.id),
      });
      close();
    }),
  );

  const isSelf = (id: string) => id === user.uid;

  return (
    <form onSubmit={changeAccess}>
      <Modal.Body>
        <div className='space-y-4'>
          <div>
            <Modal.Description className='font-medium text-md'>
              Choose who can access this file
            </Modal.Description>
            <Modal.Description>
              NB: If a user is not selected, access will be giving to all team member.
            </Modal.Description>
          </div>
          <div>
            <MembersFormField
              name='users'
              control={form.control}
              isDisabled={isSelf}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='flex justify-end space-x-2 mt-4 sticky bottom-0 bg-background py-3 border-t border-muted'>
        <Modal.Close asChild>
          <Button size='lg' variant='outline'>
            Cancel
          </Button>
        </Modal.Close>
        <Button size='lg' type='submit' loading={isChangingAccess}>
          Save
        </Button>
      </Modal.Footer>
    </form>
  );
};

type ManageFileAccessModalProps = {
  fileId: string;
};

export const ManageFileAccessModal = withModal<ManageFileAccessModalProps>(
  ({ fileId, onOpenChange }) => {
    const { company } = useUser('ManageFileAccessModal');

    const firestore = useFirestore();
    const fileDocument = doc(firestore, 'companies', company.id, 'files', fileId);
    const { data: file, status } = useFirestoreDocData<Doc>(fileDocument as any);

    return (
      <Modal.Content className='max-w-xl'>
        <Modal.Header>
          <Modal.Title className='flex gap-4 items-center'>
            <Lock width={24} height={24} className='text-primary' />
            <span>Manage File Access</span>
          </Modal.Title>
        </Modal.Header>
        {status === 'loading' ? (
          <Modal.Body>
            <div>Loading...</div>
          </Modal.Body>
        ) : status === 'error' ? (
          <Modal.Body>
            <div>Error ...</div>
          </Modal.Body>
        ) : file ? (
          <ManageFileAccessForm file={file} close={() => onOpenChange(false)} />
        ) : null}
      </Modal.Content>
    );
  },
);
