import { VegaIcon } from '../../../components/icons';
import { Button } from '../../../components/ui';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { useUI } from '@/providers/ui';
import { ACL } from '@/features/company/components';
import { useUser } from '@/providers/auth';
import { Permission } from '@/data/permission';
import {
  Bolt,
  Cross,
  FileStack,
  FileText,
  LayoutDashboard,
  ListChecks,
  Lock,
  // MessageSquareShare,
  PersonStanding,
  Receipt,
  Rocket,
  ScrollText,
  Ticket,
  UserRound,
} from 'lucide-react';

type To = LinkProps & { icon: React.FC<any>; key: string, name: string };

const links = [
  { name: 'Dashboard', to: '/', icon: LayoutDashboard, key: 'dashboard' },
  { name: 'Agreement Entries', to: '/agreements', icon: FileText, key: 'agreement' },
  { name: 'Tasks', to: '/tasks', icon: Rocket, key: 'task' },
  { name: 'Files', to: '/files', icon: FileStack, key: 'file' },
  // { name: 'Legal Marketplace', to: '/experts', icon: MessageSquareShare, key: 'legal' },
  { name: 'Team', to: '/members', icon: UserRound, key: 'team' },
  { name: 'Permissions', to: '/roles', icon: PersonStanding, key: 'role' },
  { name: 'Approval Flow', to: '/approval', icon: ListChecks, key: 'approval' },
  { name: 'Tickets', to: '/tickets', icon: Ticket, key: 'ticket' },
  { name: 'Custom Contracts', to: '/custom', icon: Receipt, key: 'custom' },
] as To[];

const links2 = [
  { name: 'Account', to: '/account', icon: Bolt, key: 'account' },
] as To[];

const links3 = [
  {
    name: 'Terms of Use',
    to: 'https://firebasestorage.googleapis.com/v0/b/vegabymypocketcounsel.appspot.com/o/assets%2FTerms%20of%20Use%20Agreement%20Final%20Copy.pdf?alt=media&token=c36a9400-1f67-404b-825c-fde9368655d0',
    icon: ScrollText,
    key: 'terms',
    target: '_blank',
  },
  {
    name: 'Privacy Policy',
    to: 'https://firebasestorage.googleapis.com/v0/b/vegabymypocketcounsel.appspot.com/o/assets%2Flawyeredup%2FLawyered%20Up%20Vega%20Privacy%20Policy.pdf?alt=media&token=d5d1fcfc-27c5-45be-9726-b38d60e463b3',
    icon: Lock,
    key: 'privacy',
    target: '_blank',
  },
] as To[];

const featureToLinkKeyMap: { [key: string]: string } = {
  'doc-automation': 'agreement',
  'task-management': 'task',
  'document-storage': 'file',
  'realtime-status': 'ticket',
  'legal-marketplace': 'legal',
  'custom-contract': 'custom',
  'approval-flow': 'approval',
  'team-management': 'team',
  'role-management': 'role',
  'account-management': 'account',
  'ticket-management': 'ticket',
  'dashboard': 'dashboard',


};

const isParentRoute = (pathname: string, href: string) => {
  const path = pathname.endsWith('/') ? pathname : `${pathname}/`;
  const route = href.endsWith('/') ? href : `${href}/`;
  if (route === '/') {
    return path === '/';
  }
  return path.startsWith(route);
};

export const Sidebar = () => {
  const { pathname } = useLocation();
  const toggleSideBar = useUI('Sidebar', (state) => state.toggleSideBar);
  const { company } = useUser('Sidebar');

  const filteredLinks = links.filter(({ key }) => {
    if (company?.plan?.type === 'custom') {
      return company.plan.features?.some((feature) => featureToLinkKeyMap[feature] === key);
    }
    if (
      company?.plan?.type === 'premium' ||
      company?.plan?.type === 'basic' ||
      company.plan?.type === 'free'
    ) {
      // return key !== 'ticket' && key !== 'legal' && key !== 'custom';
      return key !== 'ticket' && key !== 'custom';
    }
    return true;
  });

  return (
    <div className='flex flex-col h-full'>
      <div className='top-0 pt-6 px-6 pb-3 flex items-center justify-between'>
        <VegaIcon size='sm' />
        <Button
          variant='ghost'
          size='icon'
          className='md:hidden text-foreground'
          onClick={() => toggleSideBar()}
        >
          <Cross width={24} height={24} />
        </Button>
      </div>
      <ul className='pt-3'>
        {filteredLinks.map(({ key, name, icon: Icon, ...link }) => {
          const isActive = link.to && isParentRoute(pathname, link.to as string);
          return (
            <ACL key={key} permission={`admin.${key}:view` as Permission}>
              <li>
                <Link
                  {...link}
                  className={cn(
                    'relative flex items-center gap-4 px-6 py-3.5 hover:bg-primary/[0.05] text-sm text-muted-foreground transition-color',
                    {
                      'text-primary bg-primary/[0.05]': isActive,
                      'before:absolute before:content-[""] before:w-2 before:bg-primary': isActive,
                      'before:left-px before:inset-y-px before:rounded-full': isActive,
                    },
                  )}
                >
                  <Icon className='shrink-0' width={20} height={20} strokeWidth={2} />
                  <p>{name}</p>
                </Link>
              </li>
            </ACL>
          );
        })}
      </ul>
      <div className='h-px mx-6 my-2 bg-muted'></div>
      <ul>
        {links2.map(({ key, name, icon: Icon, ...link }) => {
          const isActive = link.to && isParentRoute(pathname, link.to as string);
          return (
            <ACL key={key} permission={`admin.${key}:view` as Permission}>
              <li>
                <Link
                  {...link}
                  className={cn(
                    'relative flex items-center gap-4 px-6 py-3.5 hover:bg-primary/[0.05] text-sm text-muted-foreground transition-color',
                    {
                      'text-primary bg-primary/[0.05]': isActive,
                      'before:absolute before:content-[""] before:w-2 before:bg-primary': isActive,
                      'before:left-px before:inset-y-px before:rounded-full': isActive,
                    },
                  )}
                >
                  <Icon className='shrink-0' width={20} height={20} strokeWidth={2} />
                  <p>{name}</p>
                </Link>
              </li>
            </ACL>
          );
        })}
      </ul>
      <ul className='mt-auto'>
        {links3.map(({ key, name, icon: Icon, ...link }) => {
          const isActive = link.to && isParentRoute(pathname, link.to as string);
          return (
            <li key={key}>
              <Link
                {...link}
                className={cn(
                  'relative flex items-center gap-4 px-6 py-3.5 hover:bg-primary/[0.05] text-sm text-muted-foreground transition-color',
                  {
                    'text-primary bg-primary/[0.05]': isActive,
                    'before:absolute before:content-[""] before:w-2 before:bg-primary': isActive,
                    'before:left-px before:inset-y-px before:rounded-full': isActive,
                  },
                )}
              >
                <Icon className='shrink-0' width={20} height={20} strokeWidth={2} />
                <p>{name}</p>
              </Link>
            </li>
          );
        })}
      </ul>
    </div >
  );
};
