import { defaultRoles } from '@/data/permission';
import { useUser } from '@/providers/auth';
import { useMemo } from 'react';

const backwardCompatibilityMap = {
  '1': 'admin',
  '2': 'member',
};

const useRoles = (consumerName: string) => {
  const { company } = useUser(consumerName);

  const roles = useMemo(() => {
    // merge defaultRoles and company roles
    const companyRoles = company?.roles ?? {};

    const roles = Object.values(defaultRoles);

    Object.values(companyRoles).forEach((role) => {
      // Backward compatibility - They are same role as admin and member
      // We don't want to show them twice
      // We should remove them
      if (role.id in backwardCompatibilityMap) return;
      roles.push(role);
    });

    return roles;
  }, [company.roles]);

  const normalizeRoleId = (roleId: string) => {
    if (roleId in backwardCompatibilityMap) {
      return backwardCompatibilityMap[roleId as keyof typeof backwardCompatibilityMap];
    }
    return roleId;
  };

  const getRole = (roleId: string) => {
    roleId = normalizeRoleId(roleId);
    if (roleId in defaultRoles) {
      return defaultRoles[roleId as keyof typeof defaultRoles];
    }

    return company?.roles?.[roleId];
  };

  const getRoleByLabel = (label: string) => {
    return Object.values(company?.roles ?? {}).find((role) => role.name.toLowerCase() === label.toLowerCase());
  };


  return { data: roles, normalizeRoleId, getRole, getRoleByLabel };
};

export { useRoles };
