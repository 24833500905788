export const startOfMonth = (year: number, month: number) => new Date(year, month, 1);

export const endOfMonth = (year: number, month: number) => new Date(year, month + 1, 0);

export const getDaysInMonth = (year: number, month: number, withOffsets = true) => {
  const start = startOfMonth(year, month);
  const end = endOfMonth(year, month);
  const days = [];

  if (withOffsets) {
    const offset = start.getDay();
    for (let i = offset; i > 0; i--) {
      days.push(new Date(year, month, 1 - i));
    }
  }

  for (let i = 1; i <= end.getDate(); i++) {
    days.push(new Date(year, month, i));
  }

  if (withOffsets) {
    const offset = 6 - end.getDay();
    for (let i = 1; i <= offset; i++) {
      days.push(new Date(year, month + 1, i));
    }
  }

  return days;
};

export const isDayInMonth = (day: Date, year: number, month: number) => {
  const start = startOfMonth(year, month);
  const end = endOfMonth(year, month);
  return day >= start && day <= end;
};

export const defaultDateToString = (date: Date) => {
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const defaultCalendarRangeToString = (month: number, year: number) => {
  return new Date(year, month).toLocaleDateString('en-GB', {
    month: 'long',
    year: 'numeric',
  });
};

export const composeDate = (year: number, month: number, day: number) => {
  return new Date(year, month, day);
};

export const isSameDay = (a: Date, b: Date) => {
  return (
    a.getDate() === b.getDate() &&
    a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear()
  );
};

export const minusDays = (date: Date, n: number) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - n);
};

export const addDays = (date: Date, n: number) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + n);
};
