import { useUI } from '@/providers/ui';
import { AppHeader } from '@/components/header';
import { cn } from '@/lib/utils';

export const AppLayout = ({ children, sidebar }: { children: React.ReactNode; sidebar?: React.ReactNode }) => {
  return (
    <main className='md:ml-60 min-h-screen bg-muted'>
      <SidebarWrapper sidebar={sidebar}>
        <header className='sticky h-16 flex items-center top-0 border-y border-ring z-[1] bg-background'>
          <div className='w-full max-w-7xl py-8 px-4 md:px-12'>
            <AppHeader />
          </div>
        </header>
        <div className='max-w-7xl min-h-[calc(100vh-4rem)] py-8 px-4 md:px-12'>{children}</div>
      </SidebarWrapper>
    </main>
  );
};

const SidebarWrapper = ({ children, sidebar }: { children: React.ReactNode; sidebar?: React.ReactNode }) => {
  const { isSideBarOpen, toggleSideBar } = useUI('SidebarWrapper');
  return (
    <>
      <div
        className={cn('fixed inset-0 bg-black bg-opacity-10 z-[9] md:hidden', {
          hidden: !isSideBarOpen,
        })}
        onClick={() => toggleSideBar()}
      />
      <aside
        className={cn(
          'bg-background max-w-full w-96 md:w-60 fixed inset-y-0 left-0 overflow-y-auto border-r border-ring z-10',
          'transform -translate-x-full md:translate-x-0',
          'transition-transform duration-300 ease-in-out md:transition-none',
          {
            'translate-x-0': isSideBarOpen,
            '-translate-x-full': !isSideBarOpen,
          },
        )}
      >
        {sidebar}
      </aside>
      {children}
    </>
  );
};
