import { HandWaveIcon } from '@/components/icons';
import { Button, Input, Select } from '@/components/ui';
import MultiSelect from '@/components/ui/MultiSelect';
import countries from '@/data/lawyerCountries';
import { User } from '@/lib/definitions';
import { usePromise } from '@/lib/hooks';
import { useUser } from '@/providers/auth';
import { updateProfile } from 'firebase/auth';
import { ref, update } from 'firebase/database';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth, useDatabase, useStorage, useFunctions } from 'reactfire';
import { currencyMap } from '../constant';
import { ref as sRef, uploadBytes } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';

interface LawyerDTO {
  name: string;
  email: string;
  country: string;
  experience: string;
  courtNumber: string;
  hourlyFee: string;
  calendlyLink: string;
  practiceAreas: string[];
}

const practiceAreas = [
  { value: 'criminal-law', label: 'Criminal Law' },
  { value: 'corporate-law', label: 'Corporate Law' },
  { value: 'employment-law', label: 'Employment Law' },
  { value: 'family-law', label: 'Family Law' },
  { value: 'general-law', label: 'General Law' },
  { value: 'ip-law', label: 'Intellectual Property Law' },
  { value: 'property-law', label: 'Property Law' },
];

const minimumHourlyFees = {
  BWP: 75,
  KES: 750,
  XOF: 4000,
  ZAR: 100,
  NGN: 10000,
} as const;

const countryToCurrencyCode: { [key: string]: keyof typeof minimumHourlyFees } = {
  Botswana: 'BWP',
  Kenya: 'KES',
  Nigeria: 'NGN',
  Senegal: 'XOF',
  'South Africa': 'ZAR',
};

const getMinimumHourlyFee = (country: string): number => {
  const currencyCode = countryToCurrencyCode[country];
  return minimumHourlyFees[currencyCode];
};

export const LawyerOnboarding = () => {
  const lawyerForm = useForm<LawyerDTO>();
  const { user } = useUser('LawyerOnboarding');
  const navigate = useNavigate();
  const db = useDatabase();
  const auth = useAuth();
  const storage = useStorage();
  const functions = useFunctions();
  const lawyerApprovalNotification = httpsCallable(functions, 'lawyerApprovalNotification');

  useEffect(() => {
    if (user) {
      lawyerForm.setValue('name', user.fullName ?? '');
    }
  }, [user, lawyerForm]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
      setFileError(null);
    }
  };

  const handleSubmit = async (data: LawyerDTO) => {
    const path = `lawyers/${user?.uid}`;
    const storagePath = `lawyerapprovaldocs/${user?.uid}/${selectedFile?.name}`;

    if (selectedFile) {
      const fileRef = sRef(storage, storagePath);
      await uploadBytes(fileRef, selectedFile);
    } else {
      setFileError('This field is required');
      return;
    }

    const hourlyFee = Math.max(
      parseFloat(data.hourlyFee),
      getMinimumHourlyFee(data.country),
    ).toString();

    await update(ref(db, path), {
      fullName: data.name,
      country: data.country,
      experience: data.experience,
      courtNumber: data.courtNumber,
      hourlyFee: hourlyFee,
      calendlyLink: data.calendlyLink,
      practiceAreas: data.practiceAreas,
      email: user.email,
      onBoarded: true,
      id: user.uid,
      isApproved: false,
    } as Partial<User>);

    if (auth.currentUser) {
      await updateProfile(auth.currentUser, {
        displayName: data.name,
      });
      await lawyerApprovalNotification({
        lawyerName: data.name,
        docStoragePath: storagePath,
        lawyerEmail: user.email,
      });
    }

    navigate('/');
  };

  const getCurrencySymbol = (country: string | number) => {
    let nation = country;
    if (country === 'South Africa') {
      nation = 'SouthAfrica';
    }
    return currencyMap[nation] || '$';
  };

  const [submitLawyer, isSubmittingLawyer] = usePromise(lawyerForm.handleSubmit(handleSubmit));

  return (
    <form onSubmit={submitLawyer} className='flex flex-col gap-8 mb-10'>
      <header className='space-y-2'>
        <div className='flex gap-6 items-center'>
          <HandWaveIcon size={36} className='text-secondary' />
          <h2 className='text-3xl font-medium tracking-tighter'>
            Hello, Welcome to the Vega Portal for Lawyers
          </h2>
        </div>
        <p className='text-sm text-muted-foreground'>Tell us a few things about yourself</p>
      </header>
      <div className='flex flex-col gap-6'>
        <Input
          placeholder='Jimoh Damijo'
          label='Enter your name'
          {...lawyerForm.register('name', { required: 'This field is required' })}
          error={lawyerForm.formState.errors.name?.message}
        />
        <Controller
          name='country'
          control={lawyerForm.control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <Select
              label='Country of Practice'
              options={countries}
              placeholder='Select your country of practice'
              {...field}
              onValueChange={(value) => {
                lawyerForm.setValue('country', value);
                lawyerForm.clearErrors('country');
              }}
              error={lawyerForm.formState.errors.country?.message}
            />
          )}
        />
        <Input
          placeholder='What year were you called to the bar? Ex: 2016'
          label='Year of Call'
          {...lawyerForm.register('experience', { required: 'This field is required' })}
          error={lawyerForm.formState.errors.experience?.message}
        />
        <Input
          placeholder='Enter Reg/Supreme Court Number'
          label='Reg/Supreme Court Number'
          {...lawyerForm.register('courtNumber', { required: 'This field is required' })}
          error={lawyerForm.formState.errors.courtNumber?.message}
        />
        <div className='flex flex-col gap-2'>
          <div className='text-sm font-normal text-muted-foreground mb-2'>
            Upload your proof of qualification
          </div>
          <input className='text-xs' type='file' onChange={handleFileChange} />
          <small className='text-primary'>
            We need to verify that you are professionally qualified. Please upload evidence that you
            are qualified as a lawyer in the country stated above
          </small>
        </div>
        {fileError && <small className='text-red-500'>{fileError}</small>}
        <Input
          placeholder={`Enter your hourly charge (${getCurrencySymbol(
            lawyerForm.getValues('country'),
          )})`}
          label={`Indicative Hourly Fee (${getCurrencySymbol(lawyerForm.getValues('country'))})`}
          {...lawyerForm.register('hourlyFee', {
            required: 'This field is required',
            validate: (value) => {
              const minFee = getMinimumHourlyFee(lawyerForm.getValues('country'));
              return (
                parseFloat(value) >= minFee || `Minimum hourly fee for your country is ${minFee}`
              );
            },
          })}
          error={lawyerForm.formState.errors.hourlyFee?.message}
        />
        <Controller
          name='practiceAreas'
          control={lawyerForm.control}
          rules={{
            required: 'This field is required',
            validate: (value) => value.length > 0 || 'At least one practice area must be selected',
          }}
          defaultValue={[]}
          render={({ field }) => (
            <>
              <MultiSelect
                options={practiceAreas}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                placeholder='Add Practice Areas Ex: Corporate Advisory'
                error={lawyerForm.formState.errors.practiceAreas?.message}
              />
              {lawyerForm.formState.errors.practiceAreas && (
                <small className='text-red-500'>
                  {lawyerForm.formState.errors.practiceAreas.message}
                </small>
              )}
            </>
          )}
        />
        <Input
          placeholder='Calendly link for bookings'
          label='Enter Your Calendly Link (this is how clients will book a session with you https://calendly.com/)'
          {...lawyerForm.register('calendlyLink', { required: 'This field is required' })}
          error={lawyerForm.formState.errors.calendlyLink?.message}
        />
        <Button size='lg' type='submit' disabled={isSubmittingLawyer} loading={isSubmittingLawyer}>
          Submit
        </Button>
      </div>
    </form>
  );
};
