import { createContext } from 'react';
import { JsonPage } from './types';

export type FormBuilderContextType = {
  extra: {
    user?: any;
    company?: any;
    [key: string]: any;
  };
  schema: JsonPage;
  state: Record<string, any>;
};

export const FormBuilderContext = createContext<FormBuilderContextType>(undefined as any);
