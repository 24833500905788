import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from '../../../../../components/ui';
import { useControllableState, usePromise, useYupValidationResolver } from '@/lib/hooks';
import { File } from 'lucide-react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox } from '@/components/ui/checkbox';
import { useFunctions } from 'reactfire';

type EditPDFAsDocxModalProps = {
  fileId: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  parentId?: string;
};

const schema = yup.object().shape({
  shouldMakeCopy: yup.boolean().required('Replace is required'),
});

type DTO = yup.InferType<typeof schema>;

export const EditPDFAsDocxModal = (props: EditPDFAsDocxModalProps) => {
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });

  const yupResolver = useYupValidationResolver(schema);

  const form = useForm<DTO>({
    resolver: yupResolver,
    defaultValues: {
      shouldMakeCopy: true,
    }
  });

  const functions = useFunctions();

  const generateDocument = httpsCallable(functions, 'document-generateDocxFromPdf');
  const navigate = useNavigate();

  const [submit, isSubmitting] = usePromise(
    form.handleSubmit(async ({ shouldMakeCopy }) => {
      const response = (await generateDocument({
        fileId: props.fileId,
        replace: !shouldMakeCopy,
      })) as HttpsCallableResult<{ id: string }>;
      navigate(`/file/${response.data.id}`);
    })
  );

  // if (!id) {
  //   return (
  //     <div className='py-4 sticky top-0 bg-background rounded-md z-10 space-y-4'>
  //       <div className='border-b border-stroke/50 pb-4 px-8'>
  //         <Modal.Title>Edit file with editor</Modal.Title>
  //       </div>
  //       <p className='text-lg text-muted-foreground px-8 space-y-4'>
  //         Something went wrong. Please try again later.
  //       </p>
  //       <div className='flex justify-end gap-4 px-8 py-4'>
  //         <Modal.Close asChild>
  //           <Button variant='outline' size='lg'>
  //             Cancel
  //           </Button>
  //         </Modal.Close>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      {props.children && <Modal.Trigger asChild>{props.children}</Modal.Trigger>}
      <Modal.Content>
        <Modal.Header>
          <Modal.Title className='flex gap-4 items-center'>
            <File width={24} height={24} className='text-primary' />
            <span>Edit file as docx</span>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={submit}>
          <Modal.Body className='space-y-4'>
            <Modal.Description className='text-base'>
              We convert your PDF file to a docx file so you can edit it using our editor.
            </Modal.Description>
            <Controller
              control={form.control}
              name='shouldMakeCopy'
              render={({ field }) => (
                <div className='flex items-center gap-2'>
                  <Checkbox
                    id='duplicate-chkbox'
                    checked={field.value}
                    onCheckedChange={(checked) => {
                      field.onChange(checked);
                    }}
                  />
                  <label
                    className='!text-base text-foreground cursor-pointer'
                    id='duplicate-chkbox-label'
                    htmlFor='duplicate-chkbox'
                  >
                    Create a copy of the file and edit it
                  </label>
                </div>
              )}
            />
          </Modal.Body>
          <Modal.Footer>
            <Modal.Close asChild>
              <Button size='lg' variant='outline'>
                Cancel
              </Button>
            </Modal.Close>
            <Button size='lg' type='submit' loading={isSubmitting}>
              Edit
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
