import { useControllableState, usePromise } from '@/lib/hooks';
import { Button, Popover } from '../../../../components/ui';
import { useUser } from '@/providers/auth';
import { Avatar } from '..';
import { cn } from '@/lib/utils';
import { logoutUser } from '@/actions/auth';
import { getDatabase, ref, update } from 'firebase/database';
import { app } from '@/lib/firebase';
import { useUI } from '@/providers/ui';
import { Link, useNavigate } from 'react-router-dom';
import { useUserCompanies } from '../../hooks/use-user-companies';

type AccountPopoverProps = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
};

export const AccountPopover = (props: AccountPopoverProps) => {
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });
  const { toggleSideBar } = useUI('AccountPopover', (state) => state);
  const { user } = useUser('AccountPopover');
  const [logout, isLoggingOut] = usePromise(logoutUser);
  const db = getDatabase(app);
  const navigate = useNavigate();

  const { companies } = useUserCompanies('AccountPopover');

  const handleCompanyClick = async (activeCompanyId: string) => {
    const userRef = ref(db, `users/${user?.uid}`);
    update(userRef, { activeCompanyId })
      .then(() => {
        toggleSideBar();
        onOpenChange(false);
        navigate('/');
      })
      .catch((error: any) => {
        console.error('Error updating active company:', error);
      });
  };

  return (
    <Popover.Root open={open} onOpenChange={onOpenChange}>
      {props.children && <Popover.Trigger asChild>{props.children}</Popover.Trigger>}
      <Popover.Content className='w-96 py-6 px-4 space-y-6'>
        <div className='space-y-3'>
          <p className='text-sm px-2'>Your Account</p>
          <div className='border p-3 rounded-lg cursor-pointer border-stroke/10 hover:border-stroke/30'>
            <div className='flex gap-2 items-center'>
              <Avatar name={user?.fullName} />
              <div>
                <div className='text-base leading-4 font-medium'>{user?.fullName}</div>
                <div className='text-xs text-muted-foreground lowercase'>{user?.email}</div>
              </div>
            </div>
            <div className='flex justify-end'>
              <Button variant='outline' loading={isLoggingOut} onClick={logout}>
                Sign out
              </Button>
            </div>
          </div>
        </div>
        <div className='space-y-3'>
          <p className='text-sm px-2'>Your Companies</p>
          <div className='company-list space-y-1'>
            {companies.map((company) => {
              return (
                <button
                  key={company.id}
                  onClick={() => handleCompanyClick(company.id)}
                  className={cn(
                    'flex gap-2 items-center border p-3 rounded-lg cursor-pointer border-stroke/10 hover:border-stroke/30',
                    {
                      'bg-muted': company.id === user?.activeCompanyId,
                    },
                  )}
                  style={{ width: '100%' }}
                >
                  <Avatar name={company.name} />
                  <div className='flex flex-col items-start'>
                    <div className='text-sm leading-4'>{company.name}</div>
                  </div>
                </button>
              );
            })}
          </div>
          <div className='flex justify-end'>
            <Button variant='outline'>
              <Link to='/new-company'>Add Company</Link>
            </Button>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};
