import * as F from '@/lib/firebase';
import { ref as sRef, uploadBytes } from 'firebase/storage';
import { Status, UserRef, VegaFile } from '@/lib/definitions';
import { httpsCallable } from 'firebase/functions';
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore';

type UploadFileOptions = {
  name?: string;
  folder?: VegaFile | null | string;
  users: UserRef[];
  creator: UserRef;
};

export interface AccessAlert {
  type: string;
  documentName: string;
  docId: string;
  targetEmails?: string[];
}

export const uploadFile = async (companyId: string, file: File, options: UploadFileOptions) => {
  const files = await collection(F.firestore, 'companies', companyId, 'files');
  const reference = doc(files);
  const extension = file.name.split('.').pop();
  const uploadRef = sRef(F.storage, `files/${companyId}/${reference.id}.${extension}`);
  const timeAt = new Date().toISOString();
  const snapshot = await uploadBytes(uploadRef, file);

  const document = {
    id: reference.id,
    parent:
      typeof options.folder === 'object' && options.folder !== null && 'id' in options.folder
        ? options.folder.id
        : typeof options.folder === 'string'
          ? options.folder
          : '~',
    creator: options.creator,
    type: 'file',
    accessBy: options.users,
    createdAt: timeAt,
    updatedAt: timeAt,
    name: options.name ? `${options.name}.${extension}` : file.name,
    storagePath: uploadRef.fullPath,
    draftId: '',
    size: snapshot.metadata.size,
    category: 'other',
    mode: 'uploaded',
  } as VegaFile;

  await setDoc(reference, document);
};

export const deleteFile = async (companyId: string, fileId: string) => {
  const deleteFileFn = httpsCallable(F.functions, 'deleteFile');
  await deleteFileFn({ companyId, fileId });
};

type CreateFolderParams = {
  name: string;
  parentId: string;
  accessBy: string[];
};

type CreateFolderResponse = {
  id: string;
};

export const createFolder = async (name: string, parentId: string, accessBy: string[] = []) => {
  const createFolderFn = httpsCallable<CreateFolderParams, CreateFolderResponse>(
    F.functions,
    'createFolder',
  );
  const response = await createFolderFn({ name, parentId, accessBy });
  return response.data.id;
};

export const renameFile = async (companyId: string, fileId: string, name: string) => {
  const fileRef = doc(F.firestore, 'companies', companyId, 'files', fileId);
  await updateDoc(fileRef, { name });
};

export const setFileAccess = async (companyId: string, fileId: string, accessBy: UserRef[]) => {
  const fileRef = doc(F.firestore, 'companies', companyId, 'files', fileId);
  await updateDoc(fileRef, { accessBy });
};

export const updateFileStatus = async (companyId: string, fileId: string, status: Status) => {
  const fileRef = doc(F.firestore, 'companies', companyId, 'files', fileId);
  await updateDoc(fileRef, { status });
};

export const manageAccessAlert = async (file: AccessAlert) => {
  const notifyAccess = httpsCallable(F.functions, 'manageAccessAlert');

  await notifyAccess({
    targetEmails: file.targetEmails,
    documentName: file.documentName ?? null,
    docId: file.docId ?? null,
    type: file.type,
  });
};

export const exportFiles = async (companyId: string) => {
  const getAnalytics = httpsCallable(F.functions, 'analytics-exportFiles');
  const result = await getAnalytics({ companyId });
  return result;
};
