'use client';

import * as CoreCalendar from '@/components/ui/core/calendar';
import type { CalendarRef as CoreCalendarRef } from '@/components/ui/core/calendar';
import { useControllableState } from '@/lib/hooks/state';
import { cn } from '@/lib/utils';
import { CalendarIcon, ChevronDownIcon } from '@radix-ui/react-icons';
import { forwardRef } from 'react';

type WrapperProps = {
  label?: string;
  description?: string;
  id?: string;
  error?: string;
};

export type CalendarProps = {
  value?: Date | null;
  onValueChange: (date: Date) => void;
} & WrapperProps;

const Wrapper = ({
  id,
  label,
  description,
  error,
  children,
}: React.PropsWithChildren & WrapperProps) => {
  if (!label && !error) {
    return <>{children}</>;
  }
  return (
    <div className='flex flex-col gap-2' data-error={error ? 'true' : 'false'}>
      {label && (
        <label htmlFor={id} className='text-sm font-normal text-muted-foreground'>
          {label}
        </label>
      )}
      {description && (
        <small className='text-sm font-normal text-muted-foreground'>{description}</small>
      )}
      <div className='flex flex-col gap-1'>
        {children}
        {error && <small className='text-sm font-normal text-[tomato]'>{error}</small>}
      </div>
    </div>
  );
};

export const Calendar = forwardRef<CoreCalendarRef, CalendarProps>((props, ref) => {
  const [value, onValueChange] = useControllableState({
    prop: props.value ?? undefined,
    onChange: props.onValueChange,
  });
  return (
    <Wrapper {...props}>
      <CoreCalendar.Root value={value} onValueChange={onValueChange} ref={ref}>
        <CoreCalendar.Trigger className='flex items-center justify-between h-10 w-full rounded-md border border-input bg-transparent text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 data-[state=open]:ring-2 data-[state=open]:ring-ring data-[state=open]:ring-offset-1 cursor-pointer'>
          <div className='px-6 py-5'>
            <CoreCalendar.Value />
          </div>
          <div className='px-3'>
            <CoreCalendar.Icon>
              <CalendarIcon width={20} height={20} />
            </CoreCalendar.Icon>
          </div>
        </CoreCalendar.Trigger>
        <CoreCalendar.Content className='bg-white shadow-sm p-4 rounded-lg border border-muted z-50'>
          {/* Calendar Header */}
          <div className='flex justify-between mb-4'>
            <CoreCalendar.MonthBack className='rotate-90'>
              <ChevronDownIcon />
            </CoreCalendar.MonthBack>
            <CoreCalendar.RangeValue className='text-sm font-semibold' />
            <CoreCalendar.MonthForward className='-rotate-90'>
              <ChevronDownIcon />
            </CoreCalendar.MonthForward>
          </div>
          {/* Calendar Month */}
          <div className='grid grid-cols-7 gap-2 mb-2'>
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => {
              return (
                <div
                  key={day}
                  className='text-xs font-semibold text-center text-muted-foreground/50 uppercase'
                >
                  {day}
                </div>
              );
            })}
          </div>
          {/* Calendar Days */}
          <div className='grid grid-cols-7 gap-2'>
            <CoreCalendar.Days>
              {(days) => {
                return days.map((day) => {
                  return (
                    <CoreCalendar.Day
                      key={day.toISOString()}
                      date={day}
                      className={cn(
                        'w-8 h-8 flex justify-center text-sm items-center rounded-full border border-transparent transition-colors outline-none',
                        'data-[selected=]:bg-primary data-[selected=]:text-white',
                        'data-[highlighted=]:bg-primary/10',
                        'data-[highlighted=]:data-[selected=]:bg-primary data-[highlighted=]:data-[selected=]:text-white',
                        'data-[current=]:border-primary/50',
                        'data-[outside=]:text-muted-foreground/50',
                        'data-[outside=]:data-[highlighted=]:bg-primary/10',
                        'data-[outside=]:data-[selected=]:text-muted-foreground/50 data-[outside=]:data-[selected=]:bg-transparent',
                      )}
                    >
                      {day.getDate()}
                    </CoreCalendar.Day>
                  );
                });
              }}
            </CoreCalendar.Days>
          </div>
        </CoreCalendar.Content>
      </CoreCalendar.Root>
    </Wrapper>
  );
});

Calendar.displayName = 'Calendar';

export type CalendarRef = CoreCalendarRef;
