import { cn } from '@/lib/utils';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { forwardRef, useId } from 'react';

const Root = RadixRadioGroup.Root;

const Radio = forwardRef<HTMLButtonElement, RadixRadioGroup.RadioGroupItemProps>(
  (props, forwardedRef) => (
    <RadixRadioGroup.Item
      {...props}
      ref={forwardedRef}
      className={cn(
        'border border-input w-6 h-6 rounded-full outline-none cursor-default shrink-0 focus:ring-2 focus:ring-ring focus:ring-offset-1 focus:ring-offset-background',
        props.className,
      )}
    >
      <RadixRadioGroup.Indicator className='flex items-center justify-center w-full h-full border-[6px] border-accent rounded-full' />
    </RadixRadioGroup.Item>
  ),
);

Radio.displayName = 'Radio';

export interface RadioProps extends RadixRadioGroup.RadioGroupItemProps {
  label?: string;
}

const LabelWrapper = ({
  label,
  children,
  id,
}: React.PropsWithChildren & { label: string; id: string }) => {
  if (!label) {
    return <>{children}</>;
  }

  return (
    <div className='flex gap-2'>
      {children}
      <label htmlFor={id} className='text-sm font-normal text-muted-foreground h'>
        {label}
      </label>
    </div>
  );
};

const Input = forwardRef<HTMLButtonElement, RadioProps>(({ label, id, value, ...props }, ref) => {
  const genId = useId();
  return (
    <LabelWrapper label={label!} id={id ?? genId}>
      <Radio id={id ?? genId} ref={ref} {...props} value={value} />
    </LabelWrapper>
  );
});

Input.displayName = 'RadioInput';

export const RadioGroup = {
  Root,
  Radio,
  Input,
  Item: RadixRadioGroup.Item,
  Indicator: RadixRadioGroup.Indicator,
};
