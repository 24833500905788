import * as React from 'react';
import { cn } from '@/lib/utils';
import { useControllableState } from '@/lib/hooks/state';
import ReactContentEditable, { ContentEditableEvent } from 'react-contenteditable';

const sanitizeHtml = (html: string) => {
  return html.replace(/<[^>]*>?/gm, ''); // remove all html tags
};

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  description?: string;
  error?: string;
}

const Wrapper: React.FC<
  React.PropsWithChildren & {
    label?: string;
    description?: string;
    id?: string;
    error?: string;
  }
> = ({ id, label, description, error, children }) => {
  if (!label && !error) {
    return <>{children}</>;
  }
  return (
    <div className='flex flex-col gap-1' data-error={error ? 'true' : 'false'}>
      {label && (
        <label htmlFor={id} className='text-sm font-normal text-muted-foreground'>
          {label}
        </label>
      )}
      {description && (
        <small className='text-sm font-normal text-muted-foreground'>{description}</small>
      )}
      <div className='flex flex-col gap-1'>
        {children}
        {error && <small className='text-sm font-normal text-[tomato]'>{error}</small>}
      </div>
    </div>
  );
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ id, className, type = 'text', label, error, ...props }, ref) => {
    const rId = React.useId();
    const labelId = id || rId;
    return (
      <Wrapper label={label!} id={labelId} error={error}>
        <input
          id={labelId}
          type={type}
          className={cn(
            'h-10 w-full rounded-xl border border-input bg-transparent px-6 text-[15px] text-left shadow-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:opacity-50 disabled:bg-input/20',
            className,
          )}
          ref={ref as React.Ref<HTMLInputElement>}
          {...props}
        />
      </Wrapper>
    );
  },
);

Input.displayName = 'Input';

export interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  description?: string;
  error?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ id, className, label, error, ...props }, ref) => {
    const rId = React.useId();
    const labelId = id || rId;

    return (
      <Wrapper label={label!} id={labelId} error={error}>
        <textarea
          id={id}
          {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
          className={cn(
            'h-40 w-full rounded-xl border border-input bg-transparent px-6 py-3 text-sm shadow-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          ref={ref as React.Ref<HTMLTextAreaElement>}
        />
      </Wrapper>
    );
  },
);

Textarea.displayName = 'Textarea';

type ContentEditableProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  value: string;
  onValueChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
};

const ContentEditable = React.forwardRef<HTMLDivElement, ContentEditableProps>(
  ({ value, onValueChange, placeholder, disabled, ...props }, ref) => {
    const [html, setHtml] = useControllableState({
      prop: value,
      onChange: onValueChange,
      defaultProp: '',
    });

    const handleChange = React.useCallback((event: ContentEditableEvent) => {
      setHtml(sanitizeHtml(event.target.value));
    }, []);

    return (
      <ReactContentEditable
        {...props}
        tagName='div'
        innerRef={ref as React.RefObject<HTMLDivElement>}
        contentEditable={!disabled}
        suppressContentEditableWarning
        style={
          {
            // pass in placeholder as a css variable
            '--placeholder': placeholder,
          } as any
        }
        className={cn(
          'w-full rounded-xl border border-input bg-transparent px-6 py-2.5 text-sm shadow-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50',
          // 'empty:before:content-[\'*\'] before:block before:text-sm before:px-6 before:py-2.5',
          props.className,
        )}
        onChange={handleChange}
        html={html ?? ''}
      />
    );
  },
);

ContentEditable.displayName = 'ContentEditable';

export { Input, Textarea, ContentEditable };
export type { ContentEditableProps };
