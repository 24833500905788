import { JsonProperty } from '.';
import * as React from 'react';
import { cn } from '@/lib/utils';
import {
  Input as BaseInput,
  Select as BaseSelect,
  RadioGroup as BaseRadioGroup,
  Calendar as BaseCalendar,
  Textarea as BaseTextarea,
  ContentEditable,
} from '../../../../components/ui';
import type {
  SelectProps as BaseSelectProps,
  CalendarProps as BaseCalendarProps,
  CalendarRef,
  ContentEditableProps,
  TextAreaProps,
} from '../../../../components/ui';
import { renderTemplate } from '@/lib/templater';

type PrimitiveProperty = {
  title?: string;
  description?: string;
  error?: string;
};

export const PropertyTitle: React.FC<{
  property: JsonProperty;
  context?: Record<string, any>;
}> = ({ property, context = {} }) => {
  const title = renderTemplate(property.title ?? '', { self: context });

  return (
    <header>
      {property.title && <label className='text-sm font-semibold'>{title}</label>}
      {property.description && <p className='text-muted-foreground'>{property.description}</p>}
    </header>
  );
};
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    PrimitiveProperty {}

const PropertyWrapper: React.FC<
  React.PropsWithChildren & {
    title?: string;
    description?: string;
    id?: string;
    error?: string;
    onLabelClick?: () => void;
  }
> = ({ id, title, description, error, children, onLabelClick }) => {
  if (!title && !error) {
    return <>{children}</>;
  }
  return (
    <div className='flex flex-col gap-1' data-error={error ? 'true' : 'false'}>
      <div className='flex flex-col'>
        {title && (
          <label
            htmlFor={id}
            className='text-sm font-medium text-foreground'
            onClick={onLabelClick}
          >
            {title}
          </label>
        )}
        {description && (
          <label htmlFor={id} className='text-xs font-normal text-muted-foreground'>
            {description}
          </label>
        )}
      </div>
      {error && (
        <label htmlFor={id} className='text-xs font-medium text-[tomato] mb-px'>
          {error}
        </label>
      )}
      <div className='flex flex-col gap-1'>{children}</div>
    </div>
  );
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type = 'text', title, error, description, ...props }, ref) => {
    const id = React.useId();

    return (
      <PropertyWrapper title={title} id={id} error={error} description={description}>
        <BaseInput {...props} id={id} type={type} ref={ref} />
      </PropertyWrapper>
    );
  },
);

Input.displayName = 'FormInput';

type TextareaProps = TextAreaProps &
  PrimitiveProperty & {
    onValueChange?: (value: string) => void;
  };

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ title, description, error, ...props }, ref) => {
    const id = React.useId();

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.onValueChange?.(event.target.value);
        props.onChange?.(event);
      },
      [props],
    );

    return (
      <PropertyWrapper title={title} id={id} error={error} description={description}>
        <BaseTextarea {...props} id={id} ref={ref} onChange={handleChange} />
      </PropertyWrapper>
    );
  },
);

Textarea.displayName = 'FormTextarea';

type TextareaProps2 = ContentEditableProps & PrimitiveProperty;

const Textarea2 = React.forwardRef<HTMLDivElement, TextareaProps2>(
  ({ title, description, error, ...props }, ref) => {
    const id = React.useId();

    return (
      <PropertyWrapper title={title} id={id} error={error} description={description}>
        <ContentEditable {...props} id={id} ref={ref} />
      </PropertyWrapper>
    );
  },
);

Textarea2.displayName = 'FormTextarea2';

type SelectProps = BaseSelectProps<string> & PrimitiveProperty;

const Select: React.FC<SelectProps> = ({
  className,
  title,
  label,
  error,
  description,
  ...props
}) => {
  const id = React.useId();

  return (
    <PropertyWrapper title={title ?? label} id={id} error={error} description={description}>
      <BaseSelect
        {...props}
        id={id}
        className={cn('w-full rounded-xl', className)}
        contentClassName='rounded-xl'
      />
    </PropertyWrapper>
  );
};

type CalendarProps = BaseCalendarProps & PrimitiveProperty;

const Calendar: React.FC<CalendarProps> = ({ title, error, description, ...props }) => {
  const ref = React.useRef<CalendarRef>(null);

  return (
    <PropertyWrapper
      title={title}
      error={error}
      description={description}
      onLabelClick={() => ref.current?.open?.()}
    >
      <BaseCalendar {...props} ref={ref} />
    </PropertyWrapper>
  );
};

export {
  Input,
  Textarea,
  Textarea2,
  Select,
  BaseRadioGroup as RadioGroup,
  Calendar,
  PropertyWrapper,
};
